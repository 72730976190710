import { thenable } from '../../utils/promise/thenable';
export function segmentMatcherContext(segmentName, storage) {
    return function segmentMatcher(key) {
        var isInSegment = storage.segments.isInSegment(segmentName, key);
        if (thenable(isInSegment)) {
            isInSegment.then(function (result) {
                return result;
            });
        }
        return isInSegment;
    };
}
