import { eventsSubmitterFactory } from './eventsSubmitter';
import { impressionsSubmitterFactory } from './impressionsSubmitter';
import { impressionCountsSubmitterFactory } from './impressionCountsSubmitter';
import { telemetrySubmitterFactory } from './telemetrySubmitter';
import { uniqueKeysSubmitterFactory } from './uniqueKeysSubmitter';
export function submitterManagerFactory(params) {
    var submitters = [
        impressionsSubmitterFactory(params),
        eventsSubmitterFactory(params)
    ];
    var impressionCountsSubmitter = impressionCountsSubmitterFactory(params);
    if (impressionCountsSubmitter)
        submitters.push(impressionCountsSubmitter);
    var telemetrySubmitter = telemetrySubmitterFactory(params);
    if (params.storage.uniqueKeys)
        submitters.push(uniqueKeysSubmitterFactory(params));
    return {
        // `onlyTelemetry` true if SDK is created with userConsent not GRANTED
        start: function (onlyTelemetry) {
            if (!onlyTelemetry)
                submitters.forEach(function (submitter) { return submitter.start(); });
            if (telemetrySubmitter)
                telemetrySubmitter.start();
        },
        // `allExceptTelemetry` true if userConsent is changed to DECLINED
        stop: function (allExceptTelemetry) {
            submitters.forEach(function (submitter) { return submitter.stop(); });
            if (!allExceptTelemetry && telemetrySubmitter)
                telemetrySubmitter.stop();
        },
        isRunning: function () {
            return submitters.some(function (submitter) { return submitter.isRunning(); });
        },
        // Flush data. Called with `onlyTelemetry` true if SDK is destroyed with userConsent not GRANTED
        execute: function (onlyTelemetry) {
            var promises = onlyTelemetry ? [] : submitters.map(function (submitter) { return submitter.execute(); });
            if (telemetrySubmitter)
                promises.push(telemetrySubmitter.execute());
            return Promise.all(promises);
        },
        isExecuting: function () {
            return submitters.some(function (submitter) { return submitter.isExecuting(); });
        }
    };
}
