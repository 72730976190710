var MAX_QUEUE_BYTE_SIZE = 5 * 1024 * 1024; // 5M
var EventsCacheInMemory = /** @class */ (function () {
    /**
     *
     * @param eventsQueueSize number of queued events to call onFullQueueCb.
     * Default value is 0, that means no maximum value, in case we want to avoid this being triggered.
     */
    function EventsCacheInMemory(eventsQueueSize) {
        if (eventsQueueSize === void 0) { eventsQueueSize = 0; }
        this.maxQueue = eventsQueueSize;
        this.queue = [];
        this.queueByteSize = 0;
    }
    EventsCacheInMemory.prototype.setOnFullQueueCb = function (cb) {
        this.onFullQueue = cb;
    };
    /**
     * Add a new event object at the end of the queue.
     */
    EventsCacheInMemory.prototype.track = function (data, size) {
        if (size === void 0) { size = 0; }
        this.queueByteSize += size;
        this.queue.push(data);
        this._checkForFlush();
        return true;
    };
    /**
     * Clear the data stored on the cache.
     */
    EventsCacheInMemory.prototype.clear = function () {
        this.queue = [];
        this.queueByteSize = 0;
    };
    /**
     * Pop the collected data, used as payload for posting.
     */
    EventsCacheInMemory.prototype.pop = function (toMerge) {
        var data = this.queue;
        this.clear();
        return toMerge ? toMerge.concat(data) : data;
    };
    /**
     * Check if the cache is empty.
     */
    EventsCacheInMemory.prototype.isEmpty = function () {
        return this.queue.length === 0;
    };
    /**
     * Check if the cache queue is full and we need to flush it.
     */
    EventsCacheInMemory.prototype._checkForFlush = function () {
        if ((this.queueByteSize > MAX_QUEUE_BYTE_SIZE) ||
            // 0 means no maximum value, in case we want to avoid this being triggered. Size limit is not affected by it.
            (this.maxQueue > 0 && this.queue.length >= this.maxQueue)) {
            this.onFullQueue && this.onFullQueue();
        }
    };
    return EventsCacheInMemory;
}());
export { EventsCacheInMemory };
