import { forOwn } from '../../../utils/lang';
import { syncTaskFactory } from '../../syncTask';
import { CONTROL } from '../../../utils/constants';
import { SDK_SPLITS_ARRIVED, SDK_SEGMENTS_ARRIVED, SDK_SPLITS_CACHE_LOADED } from '../../../readiness/constants';
import { SYNC_OFFLINE_DATA, ERROR_SYNC_OFFLINE_LOADING } from '../../../logger/constants';
/**
 * Offline equivalent of `splitChangesUpdaterFactory`
 */
export function fromObjectUpdaterFactory(splitsParser, storage, readiness, settings) {
    var log = settings.log, splitsCache = storage.splits;
    var startingUp = true;
    return function objectUpdater() {
        var splits = [];
        var loadError = null;
        var splitsMock = {};
        try {
            splitsMock = splitsParser(settings);
        }
        catch (err) {
            loadError = err;
            log.error(ERROR_SYNC_OFFLINE_LOADING, [err]);
        }
        if (!loadError && splitsMock) {
            log.debug(SYNC_OFFLINE_DATA, [JSON.stringify(splitsMock)]);
            forOwn(splitsMock, function (val, name) {
                splits.push([
                    name, {
                        name: name,
                        status: 'ACTIVE',
                        killed: false,
                        trafficAllocation: 100,
                        defaultTreatment: CONTROL,
                        conditions: val.conditions || [],
                        configurations: val.configurations,
                        trafficTypeName: val.trafficTypeName
                    }
                ]);
            });
            return Promise.all([
                splitsCache.clear(),
                splitsCache.addSplits(splits)
            ]).then(function () {
                readiness.splits.emit(SDK_SPLITS_ARRIVED);
                if (startingUp) {
                    startingUp = false;
                    Promise.resolve(splitsCache.checkCache()).then(function (cacheReady) {
                        // Emits SDK_READY_FROM_CACHE
                        if (cacheReady)
                            readiness.splits.emit(SDK_SPLITS_CACHE_LOADED);
                        // Emits SDK_READY
                        readiness.segments.emit(SDK_SEGMENTS_ARRIVED);
                    });
                }
                return true;
            });
        }
        else {
            return Promise.resolve(true);
        }
    };
}
/**
 * PollingManager in Offline mode
 */
export function fromObjectSyncTaskFactory(splitsParser, storage, readiness, settings) {
    return syncTaskFactory(settings.log, fromObjectUpdaterFactory(splitsParser, storage, readiness, settings), settings.scheduler.offlineRefreshRate, 'offlineUpdater');
}
