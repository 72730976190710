import * as THREE from "three";
import { Color, ColorRepresentation, Group, Vector3 } from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { TrackPoint } from "./trackUtils";
import { GUI } from "three/examples/jsm/libs/dat.gui.module";
import { DiscStats } from "./discStats";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { TECHDISC_COLOR } from "./colors";
import { FlightNumbers } from "./model/throwSummary";
// @ts-ignore
// import * as fontJson from 'three/examples/fonts/helvetiker_bold.typeface.json';

export const DRIVER_SCENE_NAME = "driver_scene";
export const FEET_TO_METERS = 0.3048;

let activeWebGLContexts = 0;

export async function loadDiscModel() {
  const loader = new GLTFLoader();
  const gltf = await loader.loadAsync("driver.glb");
  gltf.scene.traverse((child) => {
    // @ts-ignore
    if (child.isMesh) {
      child.castShadow = true;
    }
  });
  const disc = gltf.scene.getObjectByName("driver");
  // scale of gltf is in mm, but we use meters everywhere else
  disc?.scale.set(0.001, 0.001, 0.001);
  gltf.scene.name = DRIVER_SCENE_NAME;
  const light = new THREE.PointLight(0xbfbfbf, 4, 2);
  light.position.set(0.22, 0, 0.5);
  // gltf.scene.add(light);

  const light2 = new THREE.PointLight(0xbfbfbf, 4, 2);
  light2.position.set(0.22, 0, -0.5);
  // gltf.scene.add(light2);

  // in fusion z points up, but we have z point down
  const quaternion = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(1, 0, 0), Math.PI);
  disc?.applyQuaternion(quaternion);

  const material = new THREE.MeshPhysicalMaterial({
    roughness: 0.1,
    clearcoat: 1.0,
    metalness: 0.0,
    color: 0xffaf00,
  });
  // @ts-ignore
  const mesh: THREE.Mesh = disc;
  mesh.material = material;

  return gltf.scene;
}

function addFieldLine(scene: THREE.Scene, radius: number, color = 0x000000) {
  const segmentCount = 64;
  const points: THREE.Vector3[] = [];
  for (let i = 0; i <= segmentCount; i++) {
    const theta = (i / segmentCount) * Math.PI * 2;
    points.push(new THREE.Vector3(Math.cos(theta) * radius, Math.sin(theta) * radius, 0));
  }
  const geometry = new THREE.BufferGeometry().setFromPoints(points);
  const material = new THREE.LineBasicMaterial({ color });
  const line = new THREE.Line(geometry, material);
  scene.add(line);
}

export function addFieldLines(scene: THREE.Scene) {
  addFieldLine(scene, 100 * FEET_TO_METERS);
  addFieldLine(scene, 200 * FEET_TO_METERS);
  addFieldLine(scene, 300 * FEET_TO_METERS);
  addFieldLine(scene, 400 * FEET_TO_METERS);
  addFieldLine(scene, 500 * FEET_TO_METERS);
  const blue = 0x8080ff;
  addFieldLine(scene, 50 * FEET_TO_METERS, blue);
  addFieldLine(scene, 150 * FEET_TO_METERS, blue);
  addFieldLine(scene, 250 * FEET_TO_METERS, blue);
  addFieldLine(scene, 350 * FEET_TO_METERS, blue);
  addFieldLine(scene, 450 * FEET_TO_METERS, blue);
  addText(scene, "100ft", 100 * FEET_TO_METERS);
  addText(scene, "200ft", 200 * FEET_TO_METERS);
  addText(scene, "300ft", 300 * FEET_TO_METERS);
  addText(scene, "400ft", 400 * FEET_TO_METERS);
  addText(scene, "500ft", 500 * FEET_TO_METERS);
}

function addText(scene: THREE.Scene, text: string, meters: number) {
  const loader = new THREE.FontLoader();

  loader.load("helvetiker_bold.typeface.json", function (font: any) {
    const textGeo = new THREE.TextGeometry(text, {
      font: font,
      size: 500,
      height: 50,
      curveSegments: 12,

      bevelThickness: 2,
      bevelSize: 5,
      bevelEnabled: true,
    });

    const textMaterial = new THREE.MeshPhongMaterial({ color: 0x000000 });
    const mesh = new THREE.Mesh(textGeo, textMaterial);
    const scale = 0.001;
    mesh.position.set(meters, 0, 0);
    mesh.scale.set(scale, scale, scale);
    // mesh.rotation.x = Math.PI;
    mesh.rotation.z = Math.PI / 2;
    mesh.rotation.y = -Math.PI / 2;
    scene.add(mesh);
  });
}

function colorForDisc(flightNumbers: FlightNumbers): ColorRepresentation {
  if (flightNumbers.speed <= 5) {
    return 0x0000ff;
  } else {
    return 0xff0000;
  }
  // if (flightNumbers.turn < -3) {
  //   return 0xff0000;
  // } else if (flightNumbers.turn < -1) {
  //   return 0xff00ff;
  // } else if (flightNumbers.turn < 0) {
  //   return 0xff00ff;
  // } else {
  //   return 0x0000ff;
  // }
}

export function addDiscFlightPath(
  scene: THREE.Scene,
  track: TrackPoint[],
  index: number,
  discModel: Group,
  flightNumbers: FlightNumbers | undefined = undefined,
) {
  const pointsBeforeZero: THREE.Vector3[] = [];
  const points: THREE.Vector3[] = [];
  for (let i = 0; i < track.length; i++) {
    const point = track[i];
    if (point.time < 0) {
      pointsBeforeZero.push(point.position);
    } else if (point.time == 0) {
      pointsBeforeZero.push(point.position);
      points.push(point.position);
    } else {
      points.push(point.position);
    }
    // let theta = (i / segmentCount) * Math.PI * 2;
    // points.push(
    //     new THREE.Vector3(
    //         Math.cos(theta) * radius,
    //         Math.sin(theta) * radius,
    //         0));
  }
  const geometry = new THREE.BufferGeometry().setFromPoints(points);
  const color = flightNumbers ? colorForDisc(flightNumbers) : TECHDISC_COLOR.DARK_GREY;
  const material = new THREE.LineBasicMaterial({ color });
  const line = new THREE.Line(geometry, material);
  line.name = "flight_path_" + index;
  scene.add(line);

  if (flightNumbers) {
    discModel.position.set(0, 0, 1);
    // drop a disc where it lands
    const discCopy = discModel.clone(true);
    const point = points[points.length - 1];
    // discCopy.position.set(point.x, point.y, -0.03);
    discCopy.position.set(point.x, point.y, -0.1);
    discCopy.scale.set(8, 8, 8);
    discCopy.name = "disc_" + index;
    const firstChild = discCopy.children[0];

    // Check if the child is a mesh
    if (firstChild instanceof THREE.Object3D) {
      // Set the material color
      firstChild.children[0].material = firstChild.children[0].material.clone();
      firstChild.children[0].material.color.set(color);
    }
    scene.add(discCopy);
  }

  if (pointsBeforeZero.length > 1) {
    const geometry2 = new THREE.BufferGeometry().setFromPoints(pointsBeforeZero);
    const material2 = new THREE.LineBasicMaterial({ color: 0x0000ff });
    const line2 = new THREE.Line(geometry2, material2);
    line2.name = "throw_path_" + index;
    scene.add(line2);
  }
}

// an animation clip must be set to time 0 being the beginning of the animation
// We set the 0 point to when the disc leaves the hand so we return it as part of the clip, so we need to offset by that.
export function createAnimationClip(track: TrackPoint[]): THREE.AnimationClip {
  let times = track.map((point) => point.time);
  const minTime = times[0];
  // handle negative times.
  times = times.map((t) => t - minTime);
  const positions: number[] = [];

  // TODO: apply correction for angled runups
  const correction = new THREE.Quaternion().setFromAxisAngle(
    new THREE.Vector3(0, 0, 1),
    (10 * Math.PI) / 180,
  );

  track
    .map((point) => point.position)
    // .map((v) => v.applyQuaternion(correction))
    .forEach((v) => v.toArray().forEach((p) => positions.push(p)));

  const qs: number[] = [];
  track
    .map((t) => t.q)
    // .map((q) => correction.multiply(q))
    .forEach((q) => q.toArray().forEach((q) => qs.push(q)));

  const rotations = new THREE.QuaternionKeyframeTrack(DRIVER_SCENE_NAME + ".quaternion", times, qs);
  const translations = new THREE.VectorKeyframeTrack(
    DRIVER_SCENE_NAME + ".position",
    times,
    positions,
  );
  return new THREE.AnimationClip("throw", -1, [rotations, translations]);
}

export interface InitialDiscState {
  uphillAngle: number;
  rotPerSec: number;
}

interface CanvasXyz {
  x: HTMLCanvasElement;
  y: HTMLCanvasElement;
  z: HTMLCanvasElement;
}

interface AnimationConfig {
  addGui?: boolean; // defaults to true

  // This controls whether the camera is fixed in place or follows the disc
  // it also controls if the time is scrubbable or not
  // for flight path mode it just shows the lines of several flights
  isFlightPathMode?: boolean; // defaults to false
  flightNumbers?: FlightNumbers[];
  canvasXyz?: CanvasXyz;
}

function createRenderer(canvas: HTMLCanvasElement) {
  if (activeWebGLContexts >= 6) return null;
  activeWebGLContexts++;
  const renderer = new THREE.WebGLRenderer({ canvas });
  renderer.setSize(canvas.width, canvas.height);
  renderer.outputEncoding = THREE.sRGBEncoding;
  renderer.shadowMap.enabled = true;
  return renderer;
}

function disposeWebGLContext(context: THREE.WebGLRenderer | null) {
  //context.forceContextLoss();
  if (context) {
    context.dispose();
    activeWebGLContexts--;
  }
}

// negative track points are from the throw, positive are from the resulting flight
export function animateThrow(
  canvas: HTMLCanvasElement,
  summary: InitialDiscState,
  tracks: TrackPoint[][],
  _discModel: Group,
  config?: AnimationConfig,
) {
  const addGui: boolean = config?.addGui ?? true;
  const isFlightPathMode: boolean = config?.isFlightPathMode ?? false;
  const flightNumbers: FlightNumbers[] = config?.flightNumbers ?? [];
  const discModel = _discModel.clone();
  const cameraDistance = 3;
  const discNames: Array<string> = ["aviar", "roc", "wraith", "flick", "destroyer"];
  const teepadMeters = 4;
  const cameraLookHeight = 1.5;
  const frontOfTeepad = new Vector3(0, cameraLookHeight, 0);
  const centerTeepad = new Vector3(-teepadMeters / 2, cameraLookHeight, 0);

  const startingCameraPosition = isFlightPathMode
    ? new Vector3(-25, 10, 0)
    : new Vector3(-teepadMeters, cameraLookHeight, -cameraDistance);

  THREE.Cache.enabled = true;
  const scene = new THREE.Scene();
  // threejs uses y as up for some reason
  // this rotation allows us to treat everything as -Z as up aka NED coordinates
  scene.rotation.x = Math.PI / 2;
  scene.background = new THREE.Color(0x87ceeb);
  scene.fog = new THREE.Fog(0xa0a0a0, 200, 300);
  scene.add(discModel);

  // White directional light at half intensity shining from the top.
  const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
  directionalLight.position.set(0, 0, -200);
  directionalLight.castShadow = true;
  directionalLight.target = discModel;
  directionalLight.shadow.mapSize.x = 1024;
  directionalLight.shadow.mapSize.y = 1024;
  directionalLight.shadow.camera.left /= 16;
  directionalLight.shadow.camera.right /= 16;
  directionalLight.shadow.camera.top /= 16;
  directionalLight.shadow.camera.bottom /= 16;
  scene.add(directionalLight);
  // scene.add(directionalLight.shadow.camera);
  // scene.add(new THREE.CameraHelper(directionalLight.shadow.camera))

  // const hemiLight = new THREE.HemisphereLight( 0x878efb, 0xa0a0a0);
  const hemiLight = new THREE.HemisphereLight(0xffffbb, 0x080820, 1);
  // hemiLight.rotation.x = -Math.PI / 2;
  // hemiLight.position.set( 0, 0, 0 );
  // hemiLight.rotation.x = Math.PI;
  // scene.add( hemiLight );

  const mixer = new THREE.AnimationMixer(scene);
  const clip: THREE.AnimationClip = createAnimationClip(tracks[0]);
  const timeBeforeThrow = -tracks[0][0].time;
  tracks.forEach((t, index) => addDiscFlightPath(scene, t, index, discModel, flightNumbers[index]));

  const fov = isFlightPathMode ? 25 : 45;
  const camera = new THREE.PerspectiveCamera(fov, canvas.width / canvas.height, 0.1, 1000);
  const lookPositionPathMode = new Vector3(30, 0, 0);
  // camera.lookAt(-2, 1, 0);
  camera.position.set(startingCameraPosition.x, startingCameraPosition.y, startingCameraPosition.z);
  if (isFlightPathMode) {
    camera.lookAt(lookPositionPathMode);
  } else {
    camera.lookAt(frontOfTeepad);
  }
  camera.updateProjectionMatrix();
  const ambient = new THREE.AmbientLight(0x404040);
  ambient.intensity = 0.8;
  scene.add(ambient);

  const mesh = new THREE.Mesh(
    new THREE.PlaneGeometry(400, 400),
    new THREE.MeshPhongMaterial({ color: 0xb9f9b9, depthWrite: false }),
  );
  // ThreeJs has (0,1,0) for the up direction for some strange reason
  mesh.rotation.x = Math.PI;
  mesh.receiveShadow = true;
  scene.add(mesh);

  const geometry = new THREE.PlaneGeometry(teepadMeters, 2);
  const material = new THREE.MeshPhongMaterial({
    color: 0x60ff60,
    depthWrite: false,
  });

  const plane = new THREE.Mesh(geometry, material);
  plane.rotation.x = Math.PI;
  // plane.rotation.x = - Math.PI / 2;
  plane.receiveShadow = true;
  plane.position.set(-2, 0, 0);
  scene.add(plane);
  addFieldLines(scene);
  const renderer = createRenderer(canvas);

  if (renderer == null) {
    return;
  }
  let extraRender: undefined | (() => void) = undefined;
  let rendererX: THREE.WebGLRenderer | null = null;
  let rendererY: THREE.WebGLRenderer | null = null;
  let rendererZ: THREE.WebGLRenderer | null = null;

  if (config?.canvasXyz) {
    rendererX = createRenderer(config.canvasXyz.x);
    rendererY = createRenderer(config.canvasXyz.y);
    rendererZ = createRenderer(config.canvasXyz.z);

    if (rendererX == null || rendererY == null || rendererZ == null) {
      return;
    }
    // smallest box 5x3
    const cameraX = new THREE.OrthographicCamera(-2.5, 2.5, 3, 0, 0.1, 1000);
    cameraX.position.set(-100, 0, 0);
    cameraX.lookAt(0, 0, 0);
    cameraX.updateProjectionMatrix();

    // top box 11x3
    const cameraY = new THREE.OrthographicCamera(-4, 7, 3, 0, 0.1, 1000);
    cameraY.position.set(0, 0, -100);
    cameraY.lookAt(0, 0, 0);
    cameraY.updateProjectionMatrix();

    // side box overhead view 5x6
    const cameraZ = new THREE.OrthographicCamera(-2.5, 2.5, 1, -5, 0.1, 1000);
    cameraZ.position.set(0, 100, 0);
    cameraZ.lookAt(frontOfTeepad);
    cameraZ.rotateOnWorldAxis(new Vector3(0, 1, 0), -Math.PI / 2);
    cameraZ.updateProjectionMatrix();

    extraRender = () => {
      rendererX?.render(scene, cameraX);
      rendererY?.render(scene, cameraY);
      rendererZ?.render(scene, cameraZ);
    };
  }

  const clock = new THREE.Clock();

  // TODO: show path on the ground as the disc flies

  const controls: OrbitControls | undefined = isFlightPathMode
    ? new OrbitControls(camera, renderer.domElement)
    : undefined;
  if (isFlightPathMode) {
    controls?.target.set(lookPositionPathMode.x, lookPositionPathMode.y, lookPositionPathMode.z);
  } else {
    controls?.target.set(frontOfTeepad.x, frontOfTeepad.y, frontOfTeepad.z);
  }

  // const stats = new THREE.Stats();

  const setTime = (t: number) => {
    const oldScale = mixer.timeScale;
    mixer.timeScale = 1;
    mixer.setTime(t);
    mixer.timeScale = oldScale;
  };

  // let currentDisc = 'destroyer';
  // const discButtons: Map<string, any> = new Map<string, any>();
  // function changeDisc(disc: any) {
  //     currentDisc = disc;
  //     discButtons.forEach((control, name, map) => {
  //         control.classList1 = control.domElement.parentElement.parentElement.classList;
  //         control.classList2 = control.domElement.previousElementSibling.classList;
  //         control.setInactive = function () {
  //             control.classList2.add( 'control-inactive' );
  //         };
  //         control.setActive = function () {
  //             control.classList2.remove( 'control-inactive' );
  //         };
  //         if ( name === currentDisc ) {
  //             control.setActive();
  //         } else {
  //             control.setInactive();
  //         }
  //     });
  // }
  const currentStats: DiscStats = new DiscStats();
  let gui: GUI | null = null;
  if (addGui) {
    gui = new GUI({ width: 310, autoPlace: false });
    gui.domElement.id = "guiMenu";
    canvas.parentElement?.classList.add("canvas-parent");
    canvas.parentElement?.append(gui.domElement);

    // let discSettings: any = {};
    // const discFolder = gui.addFolder( 'Disc' );
    // for (let discName of discNames) {
    //     discSettings[discName] = () => changeDisc(discName);
    //     discButtons.set(discName, discFolder.add(discSettings, discName));
    // }

    // changeDisc(currentDisc);

    const folder = gui.addFolder("Playback Speed");
    const timeControl: GUI.Controller = folder
      .add(mixer, "time", 0.0, 1.0, 0.001)
      .onChange(setTime);
    timeControl.max(clip.duration);
    folder.add(mixer, "timeScale", 0.005, 1.0, 0.005);
    folder.open();

    const statsFolder = gui.addFolder("Stats");
    statsFolder.add(currentStats, "hyzer", -20, 20, 0.1).setValue = () => {
      /* nothing */
    };
    statsFolder.add(currentStats, "noseUp", -10, 10, 0.1).setValue = () => {
      /* nothing */
    };
    statsFolder.add(currentStats, "uphill", -10, 20, 0.1).setValue = () => {
      /* nothing */
    };
    statsFolder.add(currentStats, "v", 0, 60, 0.1).name("mph").setValue = () => {
      /* nothing */
    };
    statsFolder.add(currentStats, "x", -5, 400, 0.1).name("forward").setValue = () => {
      /* nothing */
    };
    statsFolder.add(currentStats, "y", -20, 20, 0.1).name("right").setValue = () => {
      /* nothing */
    };
    statsFolder.add(currentStats, "z", 0, 30, 0.1).name("up").setValue = () => {
      /* nothing */
    };
    //statsFolder.add(currentStats, 'wx', -10, 10, 0.1).setValue = () => {};
    //statsFolder.add(currentStats, 'wy', -10, 10, 0.1).setValue = () => {};
    statsFolder.add(currentStats, "wz", -200, 200, 0.1).name("rpm").setValue = () => {
      /* nothing */
    };
    //statsFolder.open()
  }

  let isCancelled = false;
  function animate() {
    if (isCancelled) {
      return;
    }
    requestAnimationFrame(animate);
    // @ts-ignore
    const driverScene: THREE.Object3D | undefined = scene.getObjectByName(DRIVER_SCENE_NAME);

    if (controls) {
      controls.update();
    }

    const previousPosition: THREE.Vector3 = driverScene?.position.clone() as THREE.Vector3;
    const previousQ: THREE.Quaternion = driverScene?.quaternion.clone() as THREE.Quaternion;

    const delta = clock.getDelta();
    mixer.update(delta);
    // stats.update();

    const deltaWithScale = delta * mixer.timeScale;
    if (!isFlightPathMode) {
      setTime(mixer.time % clip.duration);
    }

    if (camera.position.y < 0.1) {
      camera.position.y = 0.1;
    }
    if (driverScene && deltaWithScale != 0 && tracks.length && summary && !isFlightPathMode) {
      // Adjust the position of the disc and camera to look at the disc in flight
      const worldPosition = driverScene.getWorldPosition(new Vector3());
      const distance = worldPosition.distanceTo(camera.position);
      const scale = Math.pow(distance / cameraDistance, 0.2);
      driverScene.scale.set(scale, scale, scale);
      directionalLight.position.set(driverScene.position.x, driverScene.position.y, -200);

      const dpdt = driverScene.position.clone().sub(previousPosition).divideScalar(deltaWithScale);
      let v = dpdt;
      const q: THREE.Quaternion = driverScene.quaternion.clone();
      // dq = 1/2 w * q; where w is angular velocity as a vector quaternion for world coords
      // dq = 1/2 q * w; where w is angular velocity as a vector quaternion for local coords
      const dq = q.clone();
      dq.w = (q.w - previousQ.w) / deltaWithScale;
      dq.x = (q.x - previousQ.x) / deltaWithScale;
      dq.y = (q.y - previousQ.y) / deltaWithScale;
      dq.z = (q.z - previousQ.z) / deltaWithScale;
      dq.premultiply(q.clone().conjugate());
      const w = new Vector3(dq.x, dq.y, dq.z);
      w.multiplyScalar(2);
      // negative values are from the "throw" before the "flight"
      const startFlight = -tracks[0][0].time;

      if (mixer.time < startFlight) {
        const uphill = (summary.uphillAngle * Math.PI) / 180;
        v = new Vector3(Math.cos(uphill), 0, -Math.sin(uphill));
        if (!controls) {
          camera.lookAt(frontOfTeepad);
          camera.position.y = 1.5;
          camera.position.z = -cameraDistance;
          camera.zoom = 1;
          camera.updateProjectionMatrix();
        }
      } else {
        if (!controls) {
          camera.lookAt(worldPosition.x, cameraLookHeight, worldPosition.z);
          const scaleFactor = worldPosition.x / 5;
          camera.position.y = 1.5 + scaleFactor / 10;
          camera.position.z = -cameraDistance + Math.min(cameraDistance, scaleFactor);
          const angle = Math.abs(
            Math.atan((driverScene.position.z + 1) / (cameraDistance + driverScene.position.x)),
          );
          const zoomMax = Math.PI / 8 / angle;
          camera.zoom = Math.min(zoomMax, Math.max(1, scaleFactor / 1.5));
          camera.updateProjectionMatrix();
        }
      }
      const p = driverScene.position;
      currentStats.computeStats(p, dpdt, v, q, summary.rotPerSec < 0, w);
    }

    if (addGui) {
      gui.updateDisplay();
    }

    renderer?.render(scene, camera);
    extraRender?.();
  }

  animate();
  if (!isFlightPathMode) {
    mixer.clipAction(clip).play();
  }
  return () => {
    isCancelled = true;
    if (gui) {
      canvas.parentElement?.removeChild(gui.domElement);
      gui.destroy();
    }

    disposeWebGLContext(renderer);
    disposeWebGLContext(rendererX);
    disposeWebGLContext(rendererY);
    disposeWebGLContext(rendererZ);
  };
}
