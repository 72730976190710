import { __assign } from "tslib";
import React from 'react';
import { memoizeGetTreatmentsWithConfig } from './utils';
import { useSplitClient } from './useSplitClient';
/**
 * 'useSplitTreatments' is a hook that returns an SplitContext object extended with a `treatments` property object that contains feature flag evaluations.
 * It uses the 'useSplitClient' hook to access the client from the Split context, and invokes the 'client.getTreatmentsWithConfig()' method if the `names` option is provided,
 * or the 'client.getTreatmentsWithConfigByFlagSets()' method if the `flagSets` option is provided.
 *
 * @returns A Split Context object extended with a TreatmentsWithConfig instance, that might contain control treatments if the client is not available or ready, or if feature flag names do not exist.
 *
 * @example
 * ```js
 * const { treatments: { feature_1, feature_2 }, isReady, isReadyFromCache, hasTimedout, lastUpdate, ... } = useSplitTreatments({ names: ['feature_1', 'feature_2']});
 * ```
 *
 * @see {@link https://help.split.io/hc/en-us/articles/360020448791-JavaScript-SDK#get-treatments-with-configurations}
 */
export function useSplitTreatments(options) {
    var context = useSplitClient(__assign(__assign({}, options), { attributes: undefined }));
    var client = context.client, lastUpdate = context.lastUpdate;
    var names = options.names, flagSets = options.flagSets, attributes = options.attributes;
    var getTreatmentsWithConfig = React.useMemo(memoizeGetTreatmentsWithConfig, []);
    // Shallow copy `client.getAttributes` result for memoization, as it returns the same reference unless `client.clearAttributes` is invoked.
    // Note: the same issue occurs with the `names` and `attributes` arguments if they are mutated directly by the user instead of providing a new object.
    var treatments = getTreatmentsWithConfig(client, lastUpdate, names, attributes, client ? __assign({}, client.getAttributes()) : {}, flagSets);
    return __assign(__assign({}, context), { treatments: treatments });
}
