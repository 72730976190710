export const USER_ROLES = {
  USER: "USER",
  PRO_USER: "PRO_USER",
  ANONYMOUS_USER: "ANONYMOUS_USER",
  ADMIN: "ADMIN",
  MANAGER: "MANAGER",
  SUPPORT: "SUPPORT",
  DEBUG_THROWS: "DEBUG_THROWS",
  DEVELOPER: "DEVELOPER",
  STORE: "STORE",
  BETA_USER: "BETA_USER",
  EARLY_ACCESS_USER: "EARLY_ACCESS_USER",
  PREMIUM_STORE: "PREMIUM_STORE",
  MANUFACTURING: "MANUFACTURING",
} as const;
