import { objectAssign } from '../utils/lang/objectAssign';
import { releaseApiKey } from '../utils/inputValidation/apiKey';
import { clientFactory } from './client';
import { clientInputValidationDecorator } from './clientInputValidation';
var COOLDOWN_TIME_IN_MILLIS = 1000;
/**
 * Creates an Sdk client, i.e., a base client with status and destroy interface
 */
export function sdkClientFactory(params, isSharedClient) {
    var sdkReadinessManager = params.sdkReadinessManager, syncManager = params.syncManager, storage = params.storage, signalListener = params.signalListener, settings = params.settings, telemetryTracker = params.telemetryTracker, uniqueKeysTracker = params.uniqueKeysTracker;
    var lastActionTime = 0;
    function __cooldown(func, time) {
        var now = Date.now();
        //get the actual time elapsed in ms
        var timeElapsed = now - lastActionTime;
        //check if the time elapsed is less than desired cooldown
        if (timeElapsed < time) {
            //if yes, return message with remaining time in seconds
            settings.log.warn("Flush cooldown, remaining time " + (time - timeElapsed) / 1000 + " seconds");
            return Promise.resolve();
        }
        else {
            //Do the requested action and re-assign the lastActionTime
            lastActionTime = now;
            return func();
        }
    }
    function __flush() {
        return syncManager ? syncManager.flush() : Promise.resolve();
    }
    return objectAssign(
    // Proto-linkage of the readiness Event Emitter
    Object.create(sdkReadinessManager.sdkStatus), 
    // Client API (getTreatment* & track methods)
    clientInputValidationDecorator(settings, clientFactory(params), sdkReadinessManager.readinessManager), 
    // Sdk destroy
    {
        flush: function () {
            // @TODO define cooldown time
            return __cooldown(__flush, COOLDOWN_TIME_IN_MILLIS);
        },
        destroy: function () {
            // Mark the SDK as destroyed immediately
            sdkReadinessManager.readinessManager.destroy();
            // For main client, release the SDK Key and record stat before flushing data
            if (!isSharedClient) {
                releaseApiKey(settings.core.authorizationKey);
                telemetryTracker.sessionLength();
            }
            // Stop background jobs
            syncManager && syncManager.stop();
            return __flush().then(function () {
                // Cleanup event listeners
                signalListener && signalListener.stop();
                // @TODO stop only if last client is destroyed
                if (uniqueKeysTracker)
                    uniqueKeysTracker.stop();
                // Cleanup storage
                return storage.destroy();
            });
        }
    });
}
