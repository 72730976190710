import { __assign, __extends } from "tslib";
import React from 'react';
import { SplitContext } from './SplitContext';
import { getStatus, getSplitClient, initAttributes } from './utils';
import { DEFAULT_UPDATE_OPTIONS } from './useSplitClient';
/**
 * Common component used to handle the status and events of a Split client passed as prop.
 * Reused by both SplitFactoryProvider (main client) and SplitClient (any client) components.
 */
var SplitComponent = /** @class */ (function (_super) {
    __extends(SplitComponent, _super);
    function SplitComponent(props) {
        var _this = _super.call(this, props) || this;
        _this.update = function () {
            _this.setState({ lastUpdate: _this.state.client.lastUpdate });
        };
        var factory = props.factory, client = props.client;
        _this.state = __assign({ factory: factory, client: client }, getStatus(client));
        return _this;
    }
    // Using `getDerivedStateFromProps` since the state depends on the status of the client in props, which might change over time.
    // It could be avoided by removing the client and its status from the component state.
    // But it implies to have another instance property to use instead of the state, because we need a unique reference value for SplitContext.Provider
    SplitComponent.getDerivedStateFromProps = function (props, state) {
        var client = props.client, factory = props.factory, attributes = props.attributes;
        // initAttributes can be called in the `render` method too, but it is better here for separation of concerns
        initAttributes(client, attributes);
        var status = getStatus(client);
        // no need to compare status.isTimedout, since it derives from isReady and hasTimedout
        if (client !== state.client ||
            status.isReady !== state.isReady ||
            status.isReadyFromCache !== state.isReadyFromCache ||
            status.hasTimedout !== state.hasTimedout ||
            status.isDestroyed !== state.isDestroyed) {
            return __assign({ client: client, factory: factory }, status);
        }
        return null;
    };
    // Attach listeners for SDK events, to update state if client status change.
    // The listeners take into account the value of `updateOnSdk***` props.
    SplitComponent.prototype.subscribeToEvents = function (client) {
        if (client) {
            var statusOnEffect = getStatus(client);
            var status_1 = this.state;
            if (this.props.updateOnSdkReady) {
                if (!statusOnEffect.isReady)
                    client.once(client.Event.SDK_READY, this.update);
                else if (!status_1.isReady)
                    this.update();
            }
            if (this.props.updateOnSdkReadyFromCache) {
                if (!statusOnEffect.isReadyFromCache)
                    client.once(client.Event.SDK_READY_FROM_CACHE, this.update);
                else if (!status_1.isReadyFromCache)
                    this.update();
            }
            if (this.props.updateOnSdkTimedout) {
                if (!statusOnEffect.hasTimedout)
                    client.once(client.Event.SDK_READY_TIMED_OUT, this.update);
                else if (!status_1.hasTimedout)
                    this.update();
            }
            if (this.props.updateOnSdkUpdate)
                client.on(client.Event.SDK_UPDATE, this.update);
        }
    };
    SplitComponent.prototype.unsubscribeFromEvents = function (client) {
        if (client) {
            client.off(client.Event.SDK_READY, this.update);
            client.off(client.Event.SDK_READY_FROM_CACHE, this.update);
            client.off(client.Event.SDK_READY_TIMED_OUT, this.update);
            client.off(client.Event.SDK_UPDATE, this.update);
        }
    };
    SplitComponent.prototype.componentDidMount = function () {
        this.subscribeToEvents(this.props.client);
    };
    SplitComponent.prototype.componentDidUpdate = function (prevProps) {
        if (this.props.client !== prevProps.client) {
            this.unsubscribeFromEvents(prevProps.client);
            this.subscribeToEvents(this.props.client);
        }
    };
    SplitComponent.prototype.componentWillUnmount = function () {
        // unsubscribe from events, to remove references to SplitClient instance methods
        this.unsubscribeFromEvents(this.props.client);
    };
    SplitComponent.prototype.render = function () {
        var children = this.props.children;
        return (React.createElement(SplitContext.Provider, { value: this.state }, typeof children === 'function' ?
            children(__assign({}, this.state)) :
            children));
    };
    SplitComponent.defaultProps = __assign({ children: null, factory: null, client: null }, DEFAULT_UPDATE_OPTIONS);
    return SplitComponent;
}(React.Component));
export { SplitComponent };
/**
 * SplitClient will initialize a new SDK client and listen for its events in order to update the Split Context.
 * Children components will have access to the new client when accessing Split Context.
 *
 * The underlying SDK client can be changed during the component lifecycle
 * if the component is updated with a different splitKey or trafficType prop.
 *
 * @see {@link https://help.split.io/hc/en-us/articles/360020448791-JavaScript-SDK#advanced-instantiate-multiple-sdk-clients}
 */
export function SplitClient(props) {
    return (React.createElement(SplitContext.Consumer, null, function (splitContext) {
        var factory = splitContext.factory;
        // getSplitClient is idempotent like factory.client: it returns the same client given the same factory, Split Key and TT
        var client = factory ? getSplitClient(factory, props.splitKey, props.trafficType) : null;
        return (React.createElement(SplitComponent, __assign({}, props, { factory: factory, client: client, attributes: props.attributes })));
    }));
}
