import { objectAssign } from '../utils/lang/objectAssign';
import { thenable } from '../utils/promise/thenable';
import { IMPRESSIONS_TRACKER_SUCCESS, ERROR_IMPRESSIONS_TRACKER, ERROR_IMPRESSIONS_LISTENER } from '../logger/constants';
import { CONSENT_DECLINED, DEDUPED, QUEUED } from '../utils/constants';
/**
 * Impressions tracker stores impressions in cache and pass them to the listener and integrations manager if provided.
 *
 * @param impressionsCache cache to save impressions
 * @param metadata runtime metadata (ip, hostname and version)
 * @param impressionListener optional impression listener
 * @param integrationsManager optional integrations manager
 * @param strategy strategy for impressions tracking.
 */
export function impressionsTrackerFactory(settings, impressionsCache, strategy, integrationsManager, telemetryCache) {
    var log = settings.log, impressionListener = settings.impressionListener, _a = settings.runtime, ip = _a.ip, hostname = _a.hostname, version = settings.version;
    return {
        track: function (impressions, attributes) {
            if (settings.userConsent === CONSENT_DECLINED)
                return;
            var impressionsCount = impressions.length;
            var _a = strategy.process(impressions), impressionsToStore = _a.impressionsToStore, impressionsToListener = _a.impressionsToListener, deduped = _a.deduped;
            var impressionsToListenerCount = impressionsToListener.length;
            if (impressionsToStore.length > 0) {
                var res = impressionsCache.track(impressionsToStore);
                // If we're on an async storage, handle error and log it.
                if (thenable(res)) {
                    res.then(function () {
                        log.info(IMPRESSIONS_TRACKER_SUCCESS, [impressionsCount]);
                    }).catch(function (err) {
                        log.error(ERROR_IMPRESSIONS_TRACKER, [impressionsCount, err]);
                    });
                }
                else {
                    // Record when impressionsCache is sync only (standalone mode)
                    // @TODO we are not dropping impressions on full queue yet, so DROPPED stats are not recorded
                    if (telemetryCache) {
                        telemetryCache.recordImpressionStats(QUEUED, impressionsToStore.length);
                        telemetryCache.recordImpressionStats(DEDUPED, deduped);
                    }
                }
            }
            // @TODO next block might be handled by the integration manager. In that case, the metadata object doesn't need to be passed in the constructor
            if (impressionListener || integrationsManager) {
                var _loop_1 = function (i) {
                    var impressionData = {
                        // copy of impression, to avoid unexpected behaviour if modified by integrations or impressionListener
                        impression: objectAssign({}, impressionsToListener[i]),
                        attributes: attributes,
                        ip: ip,
                        hostname: hostname,
                        sdkLanguageVersion: version
                    };
                    // Wrap in a timeout because we don't want it to be blocking.
                    setTimeout(function () {
                        // integrationsManager.handleImpression does not throw errors
                        if (integrationsManager)
                            integrationsManager.handleImpression(impressionData);
                        try { // @ts-ignore. An exception on the listeners should not break the SDK.
                            if (impressionListener)
                                impressionListener.logImpression(impressionData);
                        }
                        catch (err) {
                            log.error(ERROR_IMPRESSIONS_LISTENER, [err]);
                        }
                    }, 0);
                };
                for (var i = 0; i < impressionsToListenerCount; i++) {
                    _loop_1(i);
                }
            }
        }
    };
}
