import { get } from '../utils/lang';
import { parser } from './parser';
import { keyParser } from '../utils/key';
import { thenable } from '../utils/promise/thenable';
import { EXCEPTION, NO_CONDITION_MATCH, SPLIT_ARCHIVED, SPLIT_KILLED } from '../utils/labels';
import { CONTROL } from '../utils/constants';
function evaluationResult(result, defaultTreatment) {
    return {
        treatment: get(result, 'treatment', defaultTreatment),
        label: get(result, 'label', NO_CONDITION_MATCH)
    };
}
var Engine = /** @class */ (function () {
    function Engine(baseInfo, evaluator) {
        this.baseInfo = baseInfo;
        this.evaluator = evaluator;
        // in case we don't have a default treatment in the instanciation, use 'control'
        if (typeof this.baseInfo.defaultTreatment !== 'string') {
            this.baseInfo.defaultTreatment = CONTROL;
        }
    }
    Engine.parse = function (log, splitFlatStructure, storage) {
        var conditions = splitFlatStructure.conditions;
        var evaluator = parser(log, conditions, storage);
        return new Engine(splitFlatStructure, evaluator);
    };
    Engine.prototype.getKey = function () {
        return this.baseInfo.name;
    };
    Engine.prototype.getTreatment = function (key, attributes, splitEvaluator) {
        var _a = this.baseInfo, killed = _a.killed, seed = _a.seed, defaultTreatment = _a.defaultTreatment, trafficAllocation = _a.trafficAllocation, trafficAllocationSeed = _a.trafficAllocationSeed;
        var parsedKey;
        var treatment;
        var label;
        try {
            parsedKey = keyParser(key);
        }
        catch (err) {
            return {
                treatment: CONTROL,
                label: EXCEPTION
            };
        }
        if (this.isGarbage()) {
            treatment = CONTROL;
            label = SPLIT_ARCHIVED;
        }
        else if (killed) {
            treatment = defaultTreatment;
            label = SPLIT_KILLED;
        }
        else {
            var evaluation = this.evaluator(parsedKey, seed, trafficAllocation, trafficAllocationSeed, attributes, splitEvaluator);
            // Evaluation could be async, so we should handle that case checking for a
            // thenable object
            if (thenable(evaluation)) {
                return evaluation.then(function (result) { return evaluationResult(result, defaultTreatment); });
            }
            else {
                return evaluationResult(evaluation, defaultTreatment);
            }
        }
        return {
            treatment: treatment,
            label: label
        };
    };
    Engine.prototype.isGarbage = function () {
        return this.baseInfo.status === 'ARCHIVED';
    };
    Engine.prototype.getChangeNumber = function () {
        return this.baseInfo.changeNumber;
    };
    return Engine;
}());
export { Engine };
