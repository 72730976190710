import { submitterFactory, firstPushWindowDecorator } from './submitter';
import { SUBMITTERS_PUSH_FULL_QUEUE } from '../../logger/constants';
var DATA_NAME = 'events';
/**
 * Submitter that periodically posts tracked events
 */
export function eventsSubmitterFactory(params) {
    var _a = params.settings, log = _a.log, eventsPushRate = _a.scheduler.eventsPushRate, eventsFirstPushWindow = _a.startup.eventsFirstPushWindow, postEventsBulk = params.splitApi.postEventsBulk, events = params.storage.events;
    // don't retry events.
    var submitter = submitterFactory(log, postEventsBulk, events, eventsPushRate, DATA_NAME);
    // Set a timer for the first push window of events.
    if (eventsFirstPushWindow > 0)
        submitter = firstPushWindowDecorator(submitter, eventsFirstPushWindow);
    // register events submitter to be executed when events cache is full
    events.setOnFullQueueCb(function () {
        if (submitter.isRunning()) {
            log.info(SUBMITTERS_PUSH_FULL_QUEUE, [DATA_NAME]);
            submitter.execute();
        }
        // If submitter is stopped (e.g., user consent declined or unknown, or app state offline), we don't send the data.
        // Data will be sent when submitter is resumed.
    });
    return submitter;
}
