import { objectAssign } from '../utils/lang/objectAssign';
import { thenable } from '../utils/promise/thenable';
import { find } from '../utils/lang';
import { validateSplit, validateSplitExistence, validateIfNotDestroyed, validateIfOperational } from '../utils/inputValidation';
import { isConsumerMode } from '../utils/settingsValidation/mode';
import { SPLIT_FN_LABEL, SPLITS_FN_LABEL, NAMES_FN_LABEL } from '../utils/constants';
function collectTreatments(splitObject) {
    var conditions = splitObject.conditions;
    // Rollout conditions are supposed to have the entire partitions list, so we find the first one.
    var allTreatmentsCondition = find(conditions, function (cond) { return cond.conditionType === 'ROLLOUT'; });
    // Localstorage mode could fall into a no rollout conditions state. Take the first condition in that case.
    if (!allTreatmentsCondition)
        allTreatmentsCondition = conditions[0];
    // Then extract the treatments from the partitions
    return allTreatmentsCondition ? allTreatmentsCondition.partitions.map(function (v) { return v.treatment; }) : [];
}
function objectToView(splitObject) {
    if (!splitObject)
        return null;
    return {
        name: splitObject.name,
        trafficType: splitObject.trafficTypeName,
        killed: splitObject.killed,
        changeNumber: splitObject.changeNumber || 0,
        treatments: collectTreatments(splitObject),
        configs: splitObject.configurations || {},
        sets: splitObject.sets || [],
        defaultTreatment: splitObject.defaultTreatment
    };
}
function objectsToViews(splitObjects) {
    var views = [];
    splitObjects.forEach(function (split) {
        var view = objectToView(split);
        if (view)
            views.push(view);
    });
    return views;
}
export function sdkManagerFactory(settings, splits, _a) {
    var readinessManager = _a.readinessManager, sdkStatus = _a.sdkStatus;
    var log = settings.log, mode = settings.mode;
    var isAsync = isConsumerMode(mode);
    return objectAssign(
    // Proto-linkage of the readiness Event Emitter
    Object.create(sdkStatus), {
        /**
         * Get the feature flag object corresponding to the given feature flag name if valid
         */
        split: function (featureFlagName) {
            var splitName = validateSplit(log, featureFlagName, SPLIT_FN_LABEL);
            if (!validateIfNotDestroyed(log, readinessManager, SPLIT_FN_LABEL) || !validateIfOperational(log, readinessManager, SPLIT_FN_LABEL) || !splitName) {
                return isAsync ? Promise.resolve(null) : null;
            }
            var split = splits.getSplit(splitName);
            if (thenable(split)) {
                return split.catch(function () { return null; }).then(function (result) {
                    validateSplitExistence(log, readinessManager, splitName, result, SPLIT_FN_LABEL);
                    return objectToView(result);
                });
            }
            validateSplitExistence(log, readinessManager, splitName, split, SPLIT_FN_LABEL);
            return objectToView(split);
        },
        /**
         * Get the feature flag objects present on the factory storage
         */
        splits: function () {
            if (!validateIfNotDestroyed(log, readinessManager, SPLITS_FN_LABEL) || !validateIfOperational(log, readinessManager, SPLITS_FN_LABEL)) {
                return isAsync ? Promise.resolve([]) : [];
            }
            var currentSplits = splits.getAll();
            return thenable(currentSplits) ?
                currentSplits.catch(function () { return []; }).then(objectsToViews) : // handle possible rejections when using pluggable storage
                objectsToViews(currentSplits);
        },
        /**
         * Get the feature flag names present on the factory storage
         */
        names: function () {
            if (!validateIfNotDestroyed(log, readinessManager, NAMES_FN_LABEL) || !validateIfOperational(log, readinessManager, NAMES_FN_LABEL)) {
                return isAsync ? Promise.resolve([]) : [];
            }
            var splitNames = splits.getSplitNames();
            return thenable(splitNames) ?
                splitNames.catch(function () { return []; }) : // handle possible rejections when using pluggable storage
                splitNames;
        }
    });
}
