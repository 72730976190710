import _extends from "@babel/runtime/helpers/esm/extends";
import * as React from 'react';
import useEventCallback from '@mui/utils/useEventCallback';
import { useGridLogger } from '../../utils/useGridLogger';
import { useGridApiMethod } from '../../utils/useGridApiMethod';
import { gridDensitySelector } from './densitySelector';
export const densityStateInitializer = (state, props) => _extends({}, state, {
  density: props.initialState?.density ?? props.density ?? 'standard'
});
export const useGridDensity = (apiRef, props) => {
  const logger = useGridLogger(apiRef, 'useDensity');
  apiRef.current.registerControlState({
    stateId: 'density',
    propModel: props.density,
    propOnChange: props.onDensityChange,
    stateSelector: gridDensitySelector,
    changeEvent: 'densityChange'
  });
  const setDensity = useEventCallback(newDensity => {
    const currentDensity = gridDensitySelector(apiRef.current.state);
    if (currentDensity === newDensity) {
      return;
    }
    logger.debug(`Set grid density to ${newDensity}`);
    apiRef.current.setState(state => _extends({}, state, {
      density: newDensity
    }));
  });
  React.useEffect(() => {
    if (props.density) {
      apiRef.current.setDensity(props.density);
    }
  }, [apiRef, props.density]);
  const densityApi = {
    setDensity
  };
  useGridApiMethod(apiRef, densityApi, 'public');
};