import { EXCEPTION, SDK_NOT_READY } from '../utils/labels';
import { timer } from '../utils/timeTracker/timer';
import { TOKEN_REFRESH, AUTH_REJECTION } from '../utils/constants';
export function telemetryTrackerFactory(telemetryCache, now) {
    if (telemetryCache && now) {
        var startTime_1 = timer(now);
        return {
            trackEval: function (method) {
                var evalTime = timer(now);
                return function (label) {
                    switch (label) {
                        case EXCEPTION:
                            telemetryCache.recordException(method);
                            return; // Don't track latency on exceptions
                        case SDK_NOT_READY: // @ts-ignore ITelemetryCacheAsync doesn't implement the method
                            if (telemetryCache.recordNonReadyUsage)
                                telemetryCache.recordNonReadyUsage();
                    }
                    telemetryCache.recordLatency(method, evalTime());
                };
            },
            trackHttp: function (operation) {
                var httpTime = timer(now);
                return function (error) {
                    telemetryCache.recordHttpLatency(operation, httpTime());
                    if (error && error.statusCode)
                        telemetryCache.recordHttpError(operation, error.statusCode);
                    else
                        telemetryCache.recordSuccessfulSync(operation, Date.now());
                };
            },
            sessionLength: function () {
                if (telemetryCache.recordSessionLength)
                    telemetryCache.recordSessionLength(startTime_1());
            },
            streamingEvent: function (e, d) {
                if (e === AUTH_REJECTION) {
                    telemetryCache.recordAuthRejections();
                }
                else {
                    telemetryCache.recordStreamingEvents({
                        e: e,
                        d: d,
                        t: Date.now()
                    });
                    if (e === TOKEN_REFRESH)
                        telemetryCache.recordTokenRefreshes();
                }
            },
            addTag: function (tag) {
                // @ts-ignore
                if (telemetryCache.addTag)
                    telemetryCache.addTag(tag);
            },
            trackUpdatesFromSSE: function (type) {
                telemetryCache.recordUpdatesFromSSE(type);
            }
        };
    }
    else { // If there is not `telemetryCache` or `now` time tracker, return a no-op telemetry tracker
        var noopTrack = function () { return function () { }; };
        return {
            trackEval: noopTrack,
            trackHttp: noopTrack,
            sessionLength: function () { },
            streamingEvent: function () { },
            addTag: function () { },
            trackUpdatesFromSSE: function () { },
        };
    }
}
