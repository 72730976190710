import { SDK_SPLITS_ARRIVED } from '../../../readiness/constants';
import { Backoff } from '../../../utils/Backoff';
import { SPLITS } from '../../../utils/constants';
import { FETCH_BACKOFF_BASE, FETCH_BACKOFF_MAX_WAIT, FETCH_BACKOFF_MAX_RETRIES } from './constants';
/**
 * SplitsUpdateWorker factory
 */
export function SplitsUpdateWorker(log, splitsCache, splitsSyncTask, splitsEventEmitter, telemetryTracker, segmentsSyncTask) {
    var maxChangeNumber = 0;
    var handleNewEvent = false;
    var isHandlingEvent;
    var cdnBypass;
    var payload;
    var backoff = new Backoff(__handleSplitUpdateCall, FETCH_BACKOFF_BASE, FETCH_BACKOFF_MAX_WAIT);
    function __handleSplitUpdateCall() {
        isHandlingEvent = true;
        if (maxChangeNumber > splitsCache.getChangeNumber()) {
            handleNewEvent = false;
            var splitUpdateNotification_1 = payload ? { payload: payload, changeNumber: maxChangeNumber } : undefined;
            // fetch splits revalidating data if cached
            splitsSyncTask.execute(true, cdnBypass ? maxChangeNumber : undefined, splitUpdateNotification_1).then(function () {
                if (!isHandlingEvent)
                    return; // halt if `stop` has been called
                if (handleNewEvent) {
                    __handleSplitUpdateCall();
                }
                else {
                    if (splitUpdateNotification_1)
                        telemetryTracker.trackUpdatesFromSSE(SPLITS);
                    // fetch new registered segments for server-side API. Not retrying on error
                    if (segmentsSyncTask)
                        segmentsSyncTask.execute(true);
                    var attempts = backoff.attempts + 1;
                    if (maxChangeNumber <= splitsCache.getChangeNumber()) {
                        log.debug("Refresh completed" + (cdnBypass ? ' bypassing the CDN' : '') + " in " + attempts + " attempts.");
                        isHandlingEvent = false;
                        return;
                    }
                    if (attempts < FETCH_BACKOFF_MAX_RETRIES) {
                        backoff.scheduleCall();
                        return;
                    }
                    if (cdnBypass) {
                        log.debug("No changes fetched after " + attempts + " attempts with CDN bypassed.");
                        isHandlingEvent = false;
                    }
                    else {
                        backoff.reset();
                        cdnBypass = true;
                        __handleSplitUpdateCall();
                    }
                }
            });
        }
        else {
            isHandlingEvent = false;
        }
    }
    /**
     * Invoked by NotificationProcessor on SPLIT_UPDATE event
     *
     * @param {number} changeNumber change number of the SPLIT_UPDATE notification
     */
    function put(_a, _payload) {
        var changeNumber = _a.changeNumber, pcn = _a.pcn;
        var currentChangeNumber = splitsCache.getChangeNumber();
        if (changeNumber <= currentChangeNumber || changeNumber <= maxChangeNumber)
            return;
        maxChangeNumber = changeNumber;
        handleNewEvent = true;
        cdnBypass = false;
        payload = undefined;
        if (_payload && currentChangeNumber === pcn) {
            payload = _payload;
        }
        if (backoff.timeoutID || !isHandlingEvent)
            __handleSplitUpdateCall();
        backoff.reset();
    }
    return {
        put: put,
        /**
         * Invoked by NotificationProcessor on SPLIT_KILL event
         *
         * @param {number} changeNumber change number of the SPLIT_UPDATE notification
         * @param {string} splitName name of split to kill
         * @param {string} defaultTreatment default treatment value
         */
        killSplit: function (_a) {
            var changeNumber = _a.changeNumber, splitName = _a.splitName, defaultTreatment = _a.defaultTreatment;
            if (splitsCache.killLocally(splitName, defaultTreatment, changeNumber)) {
                // trigger an SDK_UPDATE if Split was killed locally
                splitsEventEmitter.emit(SDK_SPLITS_ARRIVED, true);
            }
            // queues the SplitChanges fetch (only if changeNumber is newer)
            put({ changeNumber: changeNumber });
        },
        stop: function () {
            isHandlingEvent = false;
            backoff.reset();
        }
    };
}
