/**
 * Debug strategy for impressions tracker. Wraps impressions to store and adds previousTime if it corresponds
 *
 * @param impressionsObserver impression observer. Previous time (pt property) is included in impression instances
 * @returns IStrategyResult
 */
export function strategyDebugFactory(impressionsObserver) {
    return {
        process: function (impressions) {
            impressions.forEach(function (impression) {
                // Adds previous time if it is enabled
                impression.pt = impressionsObserver.testAndSet(impression);
            });
            return {
                impressionsToStore: impressions,
                impressionsToListener: impressions,
                deduped: 0
            };
        }
    };
}
