import { Semver } from '../../utils/Semver';
export function betweenSemverMatcherContext(ruleAttr) {
    var startSemver = new Semver(ruleAttr.start);
    var endSemver = new Semver(ruleAttr.end);
    return function betweenSemverMatcher(runtimeAttr) {
        var runtimeSemver = new Semver(runtimeAttr);
        var isBetween = startSemver.compare(runtimeSemver) <= 0 && endSemver.compare(runtimeSemver) >= 0;
        return isBetween;
    };
}
