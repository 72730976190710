import { findIndex } from '../../utils/lang';
export function containsAnySetMatcherContext(ruleAttr) {
    return function containsAnyMatcher(runtimeAttr) {
        var containsAny = false;
        var _loop_1 = function (i) {
            if (findIndex(runtimeAttr, function (e) { return e === ruleAttr[i]; }) >= 0)
                containsAny = true;
        };
        for (var i = 0; i < ruleAttr.length && !containsAny; i++) {
            _loop_1(i);
        }
        return containsAny;
    };
}
