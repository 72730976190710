// The string below is a marker and will be replaced by the real version number. DO NOT CHANGE
export var VERSION = 'react-' + '1.12.0';
// Treatments
export var ON = 'on';
export var OFF = 'off';
export var CONTROL = 'control'; // SplitIO default value
export var CONTROL_WITH_CONFIG = {
    treatment: 'control',
    config: null,
};
// Warning and error messages
export var WARN_SF_CONFIG_AND_FACTORY = '[WARN]  Both a config and factory props were provided to SplitFactoryProvider. Config prop will be ignored.';
// @TODO remove with SplitFactory component in next major version. SplitFactoryProvider can accept no props and eventually only an initialState
export var ERROR_SF_NO_CONFIG_AND_FACTORY = '[ERROR] SplitFactory must receive either a Split config or a Split factory as props.';
export var EXCEPTION_NO_REACT_OR_CREATECONTEXT = 'React library is not available or its version is not supported. Check that it is properly installed or imported. Split SDK requires version 16.3.0+ of React.';
export var WARN_NAMES_AND_FLAGSETS = '[WARN]  Both names and flagSets properties were provided. flagSets will be ignored.';
