import memoizeOne from 'memoize-one';
import shallowEqual from 'shallowequal';
import { SplitFactory as SplitSdk } from '@splitsoftware/splitio/client';
import { CONTROL_WITH_CONFIG, VERSION, WARN_NAMES_AND_FLAGSETS } from './constants';
// exported for testing purposes
export var __factories = new Map();
// idempotent operation
export function getSplitFactory(config) {
    if (!__factories.has(config)) {
        // SplitSDK is not an idempotent operation
        // @ts-expect-error. 2nd param is not part of type definitions. Used to overwrite the SDK version
        var newFactory = SplitSdk(config, function (modules) {
            modules.settings.version = VERSION;
        });
        newFactory.clientInstances = new Set();
        newFactory.config = config;
        __factories.set(config, newFactory);
    }
    return __factories.get(config);
}
// idempotent operation
export function getSplitClient(factory, key, trafficType) {
    // factory.client is an idempotent operation
    var client = (key !== undefined ? factory.client(key, trafficType) : factory.client());
    // Handle client lastUpdate
    if (client.lastUpdate === undefined) {
        // Remove EventEmitter warning emitted when using multiple SDK hooks or components.
        // Unlike JS SDK, users can avoid using the client directly, making the warning irrelevant.
        client.setMaxListeners(0);
        var updateLastUpdate = function () {
            var lastUpdate = Date.now();
            client.lastUpdate = lastUpdate > client.lastUpdate ? lastUpdate : client.lastUpdate + 1;
        };
        client.lastUpdate = 0;
        client.on(client.Event.SDK_READY, updateLastUpdate);
        client.on(client.Event.SDK_READY_FROM_CACHE, updateLastUpdate);
        client.on(client.Event.SDK_READY_TIMED_OUT, updateLastUpdate);
        client.on(client.Event.SDK_UPDATE, updateLastUpdate);
    }
    if (factory.clientInstances) {
        factory.clientInstances.add(client);
    }
    return client;
}
export function destroySplitFactory(factory) {
    // call destroy of clients
    var destroyPromises = [];
    factory.clientInstances.forEach(function (client) { return destroyPromises.push(client.destroy()); });
    // remove references to release allocated memory
    factory.clientInstances.clear();
    __factories.delete(factory.config);
    return Promise.all(destroyPromises);
}
// Util used to get client status.
// It might be removed in the future, if the JS SDK extends its public API with a `getStatus` method
export function getStatus(client) {
    var status = client && client.__getStatus();
    var isReady = status ? status.isReady : false;
    var hasTimedout = status ? status.hasTimedout : false;
    return {
        isReady: isReady,
        isReadyFromCache: status ? status.isReadyFromCache : false,
        isTimedout: hasTimedout && !isReady,
        hasTimedout: hasTimedout,
        isDestroyed: status ? status.isDestroyed : false,
        lastUpdate: client ? client.lastUpdate || 0 : 0,
    };
}
/**
 * Manage client attributes binding
 */
export function initAttributes(client, attributes) {
    if (client && attributes)
        client.setAttributes(attributes);
}
// Input validation utils that will be replaced eventually
function validateFeatureFlags(maybeFeatureFlags, listName) {
    if (listName === void 0) { listName = 'feature flag names'; }
    if (Array.isArray(maybeFeatureFlags) && maybeFeatureFlags.length > 0) {
        var validatedArray_1 = [];
        // Remove invalid values
        maybeFeatureFlags.forEach(function (maybeFeatureFlag) {
            var featureFlagName = validateFeatureFlag(maybeFeatureFlag);
            if (featureFlagName)
                validatedArray_1.push(featureFlagName);
        });
        // Strip off duplicated values if we have valid feature flag names then return
        if (validatedArray_1.length)
            return uniq(validatedArray_1);
    }
    console.log("[ERROR] " + listName + " must be a non-empty array.");
    return false;
}
var TRIMMABLE_SPACES_REGEX = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/;
function validateFeatureFlag(maybeFeatureFlag, item) {
    if (item === void 0) { item = 'feature flag name'; }
    if (maybeFeatureFlag == undefined) {
        console.log("[ERROR] you passed a null or undefined " + item + ", " + item + " must be a non-empty string.");
    }
    else if (!isString(maybeFeatureFlag)) {
        console.log("[ERROR] you passed an invalid " + item + ", " + item + " must be a non-empty string.");
    }
    else {
        if (TRIMMABLE_SPACES_REGEX.test(maybeFeatureFlag)) {
            console.log("[WARN] " + item + " \"" + maybeFeatureFlag + "\" has extra whitespace, trimming.");
            maybeFeatureFlag = maybeFeatureFlag.trim();
        }
        if (maybeFeatureFlag.length > 0) {
            return maybeFeatureFlag;
        }
        else {
            console.log("[ERROR] you passed an empty " + item + ", " + item + " must be a non-empty string.");
        }
    }
    return false;
}
export function getControlTreatmentsWithConfig(featureFlagNames) {
    // validate featureFlags Names
    var validatedFeatureFlagNames = validateFeatureFlags(featureFlagNames);
    // return empty object if the returned value is false
    if (!validatedFeatureFlagNames)
        return {};
    // return control treatments for each validated feature flag name
    return validatedFeatureFlagNames.reduce(function (pValue, cValue) {
        pValue[cValue] = CONTROL_WITH_CONFIG;
        return pValue;
    }, {});
}
/**
 * Removes duplicate items on an array of strings.
 */
function uniq(arr) {
    var seen = {};
    return arr.filter(function (item) {
        return Object.prototype.hasOwnProperty.call(seen, item) ? false : seen[item] = true;
    });
}
/**
 * Checks if a given value is a string.
 */
function isString(val) {
    return typeof val === 'string' || val instanceof String;
}
/**
 * Gets a memoized version of the `client.getTreatmentsWithConfig` method.
 * It is used to avoid duplicated impressions, because the result treatments are the same given the same `client` instance, `lastUpdate` timestamp, and list of feature flag `names` and `attributes`.
 */
export function memoizeGetTreatmentsWithConfig() {
    return memoizeOne(evaluateFeatureFlags, argsAreEqual);
}
function argsAreEqual(newArgs, lastArgs) {
    return newArgs[0] === lastArgs[0] && // client
        newArgs[1] === lastArgs[1] && // lastUpdate
        shallowEqual(newArgs[2], lastArgs[2]) && // names
        shallowEqual(newArgs[3], lastArgs[3]) && // attributes
        shallowEqual(newArgs[4], lastArgs[4]) && // client attributes
        shallowEqual(newArgs[5], lastArgs[5]); // flagSets
}
function evaluateFeatureFlags(client, _lastUpdate, names, attributes, _clientAttributes, flagSets) {
    if (names && flagSets)
        console.log(WARN_NAMES_AND_FLAGSETS);
    return client && client.__getStatus().isOperational && (names || flagSets) ?
        names ?
            client.getTreatmentsWithConfig(names, attributes) :
            client.getTreatmentsWithConfigByFlagSets(flagSets, attributes) :
        names ?
            getControlTreatmentsWithConfig(names) :
            {}; // empty object when evaluating with flag sets and client is not ready
}
