import { truncateTimeFrame } from '../../utils/time';
import { DEFAULT_CACHE_SIZE } from '../inRedis/constants';
var ImpressionCountsCacheInMemory = /** @class */ (function () {
    function ImpressionCountsCacheInMemory(impressionCountsCacheSize) {
        if (impressionCountsCacheSize === void 0) { impressionCountsCacheSize = DEFAULT_CACHE_SIZE; }
        this.cache = {};
        this.cacheSize = 0;
        this.maxStorage = impressionCountsCacheSize;
    }
    /**
    * Builds key to be stored in the cache with the featureName and the timeFrame truncated.
    */
    ImpressionCountsCacheInMemory.prototype._makeKey = function (featureName, timeFrame) {
        return featureName + "::" + truncateTimeFrame(timeFrame);
    };
    /**
    * Increments the quantity of impressions with the passed featureName and timeFrame.
    */
    ImpressionCountsCacheInMemory.prototype.track = function (featureName, timeFrame, amount) {
        var key = this._makeKey(featureName, timeFrame);
        var currentAmount = this.cache[key];
        this.cache[key] = currentAmount ? currentAmount + amount : amount;
        if (this.onFullQueue) {
            this.cacheSize = this.cacheSize + amount;
            if (this.cacheSize >= this.maxStorage) {
                this.onFullQueue();
            }
        }
    };
    /**
     * Pop the collected data, used as payload for posting.
     */
    ImpressionCountsCacheInMemory.prototype.pop = function (toMerge) {
        var data = this.cache;
        this.clear();
        if (toMerge) {
            Object.keys(data).forEach(function (key) {
                if (toMerge[key])
                    toMerge[key] += data[key];
                else
                    toMerge[key] = data[key];
            });
            return toMerge;
        }
        return data;
    };
    /**
     * Clear the data stored on the cache.
     */
    ImpressionCountsCacheInMemory.prototype.clear = function () {
        this.cache = {};
        this.cacheSize = 0;
    };
    /**
     * Check if the cache is empty.
     */
    ImpressionCountsCacheInMemory.prototype.isEmpty = function () {
        return Object.keys(this.cache).length === 0;
    };
    return ImpressionCountsCacheInMemory;
}());
export { ImpressionCountsCacheInMemory };
