import { SUBMITTERS_PUSH_FULL_QUEUE } from '../../logger/constants';
import { submitterFactory } from './submitter';
var DATA_NAME = 'unique keys';
var UNIQUE_KEYS_RATE = 900000; // 15 minutes
/**
 * Submitter that periodically posts impression counts
 */
export function uniqueKeysSubmitterFactory(params) {
    var _a = params.settings, log = _a.log, key = _a.core.key, _b = params.splitApi, postUniqueKeysBulkCs = _b.postUniqueKeysBulkCs, postUniqueKeysBulkSs = _b.postUniqueKeysBulkSs, uniqueKeys = params.storage.uniqueKeys;
    var isClientSide = key !== undefined;
    var postUniqueKeysBulk = isClientSide ? postUniqueKeysBulkCs : postUniqueKeysBulkSs;
    var syncTask = submitterFactory(log, postUniqueKeysBulk, uniqueKeys, UNIQUE_KEYS_RATE, DATA_NAME);
    // register unique keys submitter to be executed when uniqueKeys cache is full
    uniqueKeys.setOnFullQueueCb(function () {
        if (syncTask.isRunning()) {
            log.info(SUBMITTERS_PUSH_FULL_QUEUE, [DATA_NAME]);
            syncTask.execute();
        }
        // If submitter is stopped (e.g., user consent declined or unknown, or app state offline), we don't send the data.
        // Data will be sent when submitter is resumed.
    });
    return syncTask;
}
