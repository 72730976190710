import { splitApiFactory } from '@splitsoftware/splitio-commons/esm/services/splitApi';
import { syncManagerOnlineFactory } from '@splitsoftware/splitio-commons/esm/sync/syncManagerOnline';
import { pushManagerFactory } from '@splitsoftware/splitio-commons/esm/sync/streaming/pushManager';
import { pollingManagerCSFactory } from '@splitsoftware/splitio-commons/esm/sync/polling/pollingManagerCS';
import { InLocalStorage } from '@splitsoftware/splitio-commons/esm/storages/inLocalStorage';
import { InMemoryStorageCSFactory } from '@splitsoftware/splitio-commons/esm/storages/inMemory/InMemoryStorageCS';
import { sdkManagerFactory } from '@splitsoftware/splitio-commons/esm/sdkManager';
import { sdkClientMethodCSFactory } from '@splitsoftware/splitio-commons/esm/sdkClient/sdkClientMethodCSWithTT';
import { impressionObserverCSFactory } from '@splitsoftware/splitio-commons/esm/trackers/impressionObserver/impressionObserverCS';
import { integrationsManagerFactory } from '@splitsoftware/splitio-commons/esm/integrations/browser';
import { __InLocalStorageMockFactory } from '@splitsoftware/splitio-commons/esm/utils/settingsValidation/storage/storageCS';
import { sdkFactory } from '@splitsoftware/splitio-commons/esm/sdkFactory';
import { LOCALHOST_MODE, STORAGE_LOCALSTORAGE } from '@splitsoftware/splitio-commons/esm/utils/constants';
import { createUserConsentAPI } from '@splitsoftware/splitio-commons/esm/consent/sdkUserConsent';
import { settingsFactory } from '../settings/browser';
import { platform, SignalListener } from '../platform';
var syncManagerOnlineCSFactory = syncManagerOnlineFactory(pollingManagerCSFactory, pushManagerFactory);
function getStorage(settings) {
    return settings.storage.type === STORAGE_LOCALSTORAGE ?
        InLocalStorage(settings.storage)
        : settings.storage.__originalType === STORAGE_LOCALSTORAGE ?
            __InLocalStorageMockFactory
            : InMemoryStorageCSFactory;
}
/**
 *
 * @param {import("@splitsoftware/splitio-commons/types/types").ISettings} settings
 */
function getModules(settings) {
    var modules = {
        settings: settings,
        platform: platform,
        storageFactory: getStorage(settings),
        splitApiFactory: splitApiFactory,
        syncManagerFactory: syncManagerOnlineCSFactory,
        sdkManagerFactory: sdkManagerFactory,
        sdkClientMethodFactory: sdkClientMethodCSFactory,
        SignalListener: SignalListener,
        integrationsManagerFactory: settings.integrations && settings.integrations.length > 0 ? integrationsManagerFactory.bind(null, settings.integrations) : undefined,
        impressionsObserverFactory: impressionObserverCSFactory,
        extraProps: function (params) {
            return {
                UserConsent: createUserConsentAPI(params)
            };
        }
    };
    switch (settings.mode) {
        case LOCALHOST_MODE:
            modules.splitApiFactory = undefined;
            modules.syncManagerFactory = settings.sync.localhostMode;
            modules.SignalListener = undefined;
            break;
    }
    return modules;
}
/**
 * SplitFactory for client-side.
 *
 * @param {SplitIO.IBrowserSettings} config configuration object used to instantiate the SDK
 * @param {Function=} __updateModules optional function that lets redefine internal SDK modules. Use with
 * caution since, unlike `config`, this param is not validated neither considered part of the public API.
 * @throws Will throw an error if the provided config is invalid.
 */
export function SplitFactory(config, __updateModules) {
    var settings = settingsFactory(config);
    var modules = getModules(settings);
    if (__updateModules)
        __updateModules(modules);
    return sdkFactory(modules);
}
