import { __assign } from "tslib";
import React from 'react';
import { SplitContext } from './SplitContext';
import { getSplitClient, initAttributes, getStatus } from './utils';
export var DEFAULT_UPDATE_OPTIONS = {
    updateOnSdkUpdate: false,
    updateOnSdkTimedout: false,
    updateOnSdkReady: true,
    updateOnSdkReadyFromCache: true,
};
/**
 * 'useSplitClient' is a hook that returns an Split Context object with the client and its status corresponding to the provided key and trafficType.
 * It uses the 'useContext' hook to access the context, which is updated by SplitFactoryProvider and SplitClient components in the hierarchy of components.
 *
 * @returns A Split Context object
 *
 * @example
 * ```js
 * const { factory, client, isReady, isReadyFromCache, hasTimedout, lastUpdate } = useSplitClient({ splitKey: 'user_id' });
 * ```
 *
 * @see {@link https://help.split.io/hc/en-us/articles/360020448791-JavaScript-SDK#advanced-instantiate-multiple-sdk-clients}
 */
export function useSplitClient(options) {
    var _a = __assign(__assign({}, DEFAULT_UPDATE_OPTIONS), options), updateOnSdkReady = _a.updateOnSdkReady, updateOnSdkReadyFromCache = _a.updateOnSdkReadyFromCache, updateOnSdkTimedout = _a.updateOnSdkTimedout, updateOnSdkUpdate = _a.updateOnSdkUpdate, splitKey = _a.splitKey, trafficType = _a.trafficType, attributes = _a.attributes;
    var context = React.useContext(SplitContext);
    var contextClient = context.client, factory = context.factory;
    var client = contextClient;
    if (splitKey && factory) {
        // @TODO `getSplitClient` starts client sync. Move side effects to useEffect
        client = getSplitClient(factory, splitKey, trafficType);
    }
    initAttributes(client, attributes);
    var status = getStatus(client);
    var _b = React.useState(status.lastUpdate), setLastUpdate = _b[1];
    // Handle client events
    React.useEffect(function () {
        if (!client)
            return;
        var update = function () { return setLastUpdate(client.lastUpdate); };
        // Clients are created on the hook's call, so the status may have changed
        var statusOnEffect = getStatus(client);
        // Subscribe to SDK events
        if (updateOnSdkReady) {
            if (!statusOnEffect.isReady)
                client.once(client.Event.SDK_READY, update);
            else if (!status.isReady)
                update();
        }
        if (updateOnSdkReadyFromCache) {
            if (!statusOnEffect.isReadyFromCache)
                client.once(client.Event.SDK_READY_FROM_CACHE, update);
            else if (!status.isReadyFromCache)
                update();
        }
        if (updateOnSdkTimedout) {
            if (!statusOnEffect.hasTimedout)
                client.once(client.Event.SDK_READY_TIMED_OUT, update);
            else if (!status.hasTimedout)
                update();
        }
        if (updateOnSdkUpdate)
            client.on(client.Event.SDK_UPDATE, update);
        return function () {
            // Unsubscribe from events
            client.off(client.Event.SDK_READY, update);
            client.off(client.Event.SDK_READY_FROM_CACHE, update);
            client.off(client.Event.SDK_READY_TIMED_OUT, update);
            client.off(client.Event.SDK_UPDATE, update);
        };
    }, [client, updateOnSdkReady, updateOnSdkReadyFromCache, updateOnSdkTimedout, updateOnSdkUpdate, status]);
    return __assign({ factory: factory, client: client }, status);
}
