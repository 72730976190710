import { _Set, setToArray } from '../../../utils/lang/sets';
import { timeout } from '../../../utils/promise/timeout';
import { SDK_SPLITS_ARRIVED, SDK_SPLITS_CACHE_LOADED } from '../../../readiness/constants';
import { SYNC_SPLITS_FETCH, SYNC_SPLITS_NEW, SYNC_SPLITS_REMOVED, SYNC_SPLITS_SEGMENTS, SYNC_SPLITS_FETCH_FAILS, SYNC_SPLITS_FETCH_RETRY } from '../../../logger/constants';
import { startsWith } from '../../../utils/lang';
// Checks that all registered segments have been fetched (changeNumber !== -1 for every segment).
// Returns a promise that could be rejected.
// @TODO review together with Segments and MySegments storage APIs
function checkAllSegmentsExist(segments) {
    var registeredSegments = Promise.resolve(segments.getRegisteredSegments());
    return registeredSegments.then(function (segmentNames) {
        return Promise.all(segmentNames.map(function (segmentName) { return segments.getChangeNumber(segmentName); }))
            .then(function (changeNumbers) { return changeNumbers.every(function (changeNumber) { return changeNumber !== -1; }); });
    });
}
/**
 * Collect segments from a raw split definition.
 * Exported for testing purposes.
 */
export function parseSegments(_a) {
    var conditions = _a.conditions;
    var segments = new _Set();
    for (var i = 0; i < conditions.length; i++) {
        var matchers = conditions[i].matcherGroup.matchers;
        matchers.forEach(function (matcher) {
            if (matcher.matcherType === 'IN_SEGMENT')
                segments.add(matcher.userDefinedSegmentMatcherData.segmentName);
        });
    }
    return segments;
}
/**
 * If there are defined filters and one feature flag doesn't match with them, its status is changed to 'ARCHIVE' to avoid storing it
 * If there are set filter defined, names filter is ignored
 *
 * @param featureFlag feature flag to be evaluated
 * @param filters splitFiltersValidation bySet | byName
 */
function matchFilters(featureFlag, filters) {
    var _a = filters.groupedFilters, setsFilter = _a.bySet, namesFilter = _a.byName, prefixFilter = _a.byPrefix;
    if (setsFilter.length > 0)
        return featureFlag.sets && featureFlag.sets.some(function (featureFlagSet) { return setsFilter.indexOf(featureFlagSet) > -1; });
    var namesFilterConfigured = namesFilter.length > 0;
    var prefixFilterConfigured = prefixFilter.length > 0;
    if (!namesFilterConfigured && !prefixFilterConfigured)
        return true;
    var matchNames = namesFilterConfigured && namesFilter.indexOf(featureFlag.name) > -1;
    var matchPrefix = prefixFilterConfigured && prefixFilter.some(function (prefix) { return startsWith(featureFlag.name, prefix); });
    return matchNames || matchPrefix;
}
/**
 * Given the list of splits from /splitChanges endpoint, it returns the mutations,
 * i.e., an object with added splits, removed splits and used segments.
 * Exported for testing purposes.
 */
export function computeSplitsMutation(entries, filters) {
    var segments = new _Set();
    var computed = entries.reduce(function (accum, split) {
        if (split.status === 'ACTIVE' && matchFilters(split, filters)) {
            accum.added.push([split.name, split]);
            parseSegments(split).forEach(function (segmentName) {
                segments.add(segmentName);
            });
        }
        else {
            accum.removed.push(split.name);
        }
        return accum;
    }, { added: [], removed: [], segments: [] });
    computed.segments = setToArray(segments);
    return computed;
}
/**
 * factory of SplitChanges updater, a task that:
 *  - fetches split changes using `splitChangesFetcher`
 *  - updates `splitsCache`
 *  - uses `splitsEventEmitter` to emit events related to split data updates
 *
 * @param log  Logger instance
 * @param splitChangesFetcher  Fetcher of `/splitChanges`
 * @param splits  Splits storage, with sync or async methods
 * @param segments  Segments storage, with sync or async methods
 * @param splitsEventEmitter  Optional readiness manager. Not required for synchronizer or producer mode.
 * @param requestTimeoutBeforeReady  How long the updater will wait for the request to timeout. Default 0, i.e., never timeout.
 * @param retriesOnFailureBeforeReady  How many retries on `/splitChanges` we the updater do in case of failure or timeout. Default 0, i.e., no retries.
 */
export function splitChangesUpdaterFactory(log, splitChangesFetcher, splits, segments, splitFiltersValidation, splitsEventEmitter, requestTimeoutBeforeReady, retriesOnFailureBeforeReady, isClientSide) {
    if (requestTimeoutBeforeReady === void 0) { requestTimeoutBeforeReady = 0; }
    if (retriesOnFailureBeforeReady === void 0) { retriesOnFailureBeforeReady = 0; }
    var startingUp = true;
    /** timeout decorator for `splitChangesFetcher` promise  */
    function _promiseDecorator(promise) {
        if (startingUp && requestTimeoutBeforeReady)
            promise = timeout(requestTimeoutBeforeReady, promise);
        return promise;
    }
    /** Returns true if at least one split was updated */
    function isThereUpdate(flagsChange) {
        var added = flagsChange[1], removed = flagsChange[2];
        // There is at least one added or modified feature flag
        if (added && added.some(function (update) { return update; }))
            return true;
        // There is at least one removed feature flag
        if (removed && removed.some(function (update) { return update; }))
            return true;
        return false;
    }
    /**
     * SplitChanges updater returns a promise that resolves with a `false` boolean value if it fails to fetch splits or synchronize them with the storage.
     * Returned promise will not be rejected.
     *
     * @param {boolean | undefined} noCache true to revalidate data to fetch
     * @param {boolean | undefined} till query param to bypass CDN requests
     */
    return function splitChangesUpdater(noCache, till, splitUpdateNotification) {
        /**
         * @param {number} since current changeNumber at splitsCache
         * @param {number} retry current number of retry attempts
         */
        function _splitChangesUpdater(since, retry) {
            if (retry === void 0) { retry = 0; }
            log.debug(SYNC_SPLITS_FETCH, [since]);
            var fetcherPromise = Promise.resolve(splitUpdateNotification ?
                { splits: [splitUpdateNotification.payload], till: splitUpdateNotification.changeNumber } :
                splitChangesFetcher(since, noCache, till, _promiseDecorator))
                .then(function (splitChanges) {
                startingUp = false;
                var mutation = computeSplitsMutation(splitChanges.splits, splitFiltersValidation);
                log.debug(SYNC_SPLITS_NEW, [mutation.added.length]);
                log.debug(SYNC_SPLITS_REMOVED, [mutation.removed.length]);
                log.debug(SYNC_SPLITS_SEGMENTS, [mutation.segments.length]);
                // Write into storage
                // @TODO call `setChangeNumber` only if the other storage operations have succeeded, in order to keep storage consistency
                return Promise.all([
                    // calling first `setChangenumber` method, to perform cache flush if split filter queryString changed
                    splits.setChangeNumber(splitChanges.till),
                    splits.addSplits(mutation.added),
                    splits.removeSplits(mutation.removed),
                    segments.registerSegments(mutation.segments)
                ]).then(function (flagsChange) {
                    if (splitsEventEmitter) {
                        // To emit SDK_SPLITS_ARRIVED for server-side SDK, we must check that all registered segments have been fetched
                        return Promise.resolve(!splitsEventEmitter.splitsArrived || (since !== splitChanges.till && isThereUpdate(flagsChange) && (isClientSide || checkAllSegmentsExist(segments))))
                            .catch(function () { return false; } /** noop. just to handle a possible `checkAllSegmentsExist` rejection, before emitting SDK event */)
                            .then(function (emitSplitsArrivedEvent) {
                            // emit SDK events
                            if (emitSplitsArrivedEvent)
                                splitsEventEmitter.emit(SDK_SPLITS_ARRIVED);
                            return true;
                        });
                    }
                    return true;
                });
            })
                .catch(function (error) {
                log.warn(SYNC_SPLITS_FETCH_FAILS, [error]);
                if (startingUp && retriesOnFailureBeforeReady > retry) {
                    retry += 1;
                    log.info(SYNC_SPLITS_FETCH_RETRY, [retry, error]);
                    return _splitChangesUpdater(since, retry);
                }
                else {
                    startingUp = false;
                }
                return false;
            });
            // After triggering the requests, if we have cached splits information let's notify that to emit SDK_READY_FROM_CACHE.
            // Wrapping in a promise since checkCache can be async.
            if (splitsEventEmitter && startingUp) {
                Promise.resolve(splits.checkCache()).then(function (isCacheReady) {
                    if (isCacheReady)
                        splitsEventEmitter.emit(SDK_SPLITS_CACHE_LOADED);
                });
            }
            return fetcherPromise;
        }
        var sincePromise = Promise.resolve(splits.getChangeNumber()); // `getChangeNumber` never rejects or throws error
        return sincePromise.then(_splitChangesUpdater);
    };
}
