import { clientCSDecorator } from './clientCS';
import { validateKey } from '../utils/inputValidation/key';
import { validateTrafficType } from '../utils/inputValidation/trafficType';
import { getMatching, keyParser } from '../utils/key';
import { sdkClientFactory } from './sdkClient';
import { objectAssign } from '../utils/lang/objectAssign';
import { RETRIEVE_CLIENT_DEFAULT, NEW_SHARED_CLIENT, RETRIEVE_CLIENT_EXISTING } from '../logger/constants';
import { SDK_SEGMENTS_ARRIVED } from '../readiness/constants';
function buildInstanceId(key, trafficType) {
    // @ts-ignore
    return (key.matchingKey ? key.matchingKey : key) + "-" + (key.bucketingKey ? key.bucketingKey : key) + "-" + (trafficType !== undefined ? trafficType : '');
}
var method = 'Client instantiation';
/**
 * Factory of client method for the client-side (browser) variant of the Isomorphic JS SDK,
 * where clients can have a bound TT for the track method, which is provided via the settings
 * (default client) or the client method (shared clients).
 */
export function sdkClientMethodCSFactory(params) {
    var storage = params.storage, syncManager = params.syncManager, sdkReadinessManager = params.sdkReadinessManager, _a = params.settings, _b = _a.core, key = _b.key, trafficType = _b.trafficType, readyTimeout = _a.startup.readyTimeout, log = _a.log;
    var mainClientInstance = clientCSDecorator(log, sdkClientFactory(params), key, trafficType);
    var parsedDefaultKey = keyParser(key);
    var defaultInstanceId = buildInstanceId(parsedDefaultKey, trafficType);
    // Cache instances created per factory.
    var clientInstances = {};
    clientInstances[defaultInstanceId] = mainClientInstance;
    return function client(key, trafficType) {
        if (key === undefined) {
            log.debug(RETRIEVE_CLIENT_DEFAULT);
            return mainClientInstance;
        }
        // Validate the key value
        var validKey = validateKey(log, key, "Shared " + method);
        if (validKey === false) {
            throw new Error('Shared Client needs a valid key.');
        }
        var validTrafficType;
        if (trafficType !== undefined) {
            validTrafficType = validateTrafficType(log, trafficType, "Shared " + method);
            if (validTrafficType === false) {
                throw new Error('Shared Client needs a valid traffic type or no traffic type at all.');
            }
        }
        var instanceId = buildInstanceId(validKey, validTrafficType);
        if (!clientInstances[instanceId]) {
            var matchingKey = getMatching(validKey);
            var sharedSdkReadiness_1 = sdkReadinessManager.shared(readyTimeout);
            var sharedStorage = storage.shared && storage.shared(matchingKey, function (err) {
                if (err) {
                    sharedSdkReadiness_1.readinessManager.timeout();
                    return;
                }
                // Emit SDK_READY in consumer mode for shared clients
                sharedSdkReadiness_1.readinessManager.segments.emit(SDK_SEGMENTS_ARRIVED);
            });
            // 3 possibilities:
            // - Standalone mode: both syncManager and sharedSyncManager are defined
            // - Consumer mode: both syncManager and sharedSyncManager are undefined
            // - Consumer partial mode: syncManager is defined (only for submitters) but sharedSyncManager is undefined
            // @ts-ignore
            var sharedSyncManager = syncManager && sharedStorage && syncManager.shared(matchingKey, sharedSdkReadiness_1.readinessManager, sharedStorage);
            // As shared clients reuse all the storage information, we don't need to check here if we
            // will use offline or online mode. We should stick with the original decision.
            clientInstances[instanceId] = clientCSDecorator(log, sdkClientFactory(objectAssign({}, params, {
                sdkReadinessManager: sharedSdkReadiness_1,
                storage: sharedStorage || storage,
                syncManager: sharedSyncManager,
                signalListener: undefined, // only the main client "destroy" method stops the signal listener
            }), true), validKey, validTrafficType);
            sharedSyncManager && sharedSyncManager.start();
            log.info(NEW_SHARED_CLIENT);
        }
        else {
            log.debug(RETRIEVE_CLIENT_EXISTING);
        }
        return clientInstances[instanceId];
    };
}
