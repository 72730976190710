import React, { useDeferredValue, useEffect, useMemo, useState } from "react";
import { getTrueUserId, getUserId, storeDevice } from "../summaryUtils";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import { firebaseApp } from "../firebaseConfig";
import { doc, getFirestore } from "firebase/firestore";
import { useCollectionData, useDocument } from "react-firebase-hooks/firestore";
import ColorPicker from "./ColorPicker";
import { type Device } from "../model/device";
import {
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  type SelectChangeEvent,
} from "@mui/material";
import { FlightNumbers } from "../model/throwSummary";
import Popover from "@mui/material/Popover";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import { DEFAULT_DISC_STATE } from "./SimDashboard";
import { FlightNumberBoxes, FlightNumberSliders } from "./FlightNumberChooser";
import { LIVE_USER_ID } from "./liveEvents";
import { useGlobal } from "../components/providers/GlobalProvider";
import { DiscPresetSelect } from "./DiscPresetSelect";
import useNotify from "../hooks/useNotify";
import { getUserDiscPresets } from "../firebase/collections";
import useRoleCheck from "../hooks/useRoleCheck";

type Props = {
  deviceId: string;
};

function FlightNumberButton(props: { device?: Device; deviceId: string }) {
  const { device, deviceId } = props;
  const [flightNums, setFlightNums] = useState<FlightNumbers | undefined>(
    device?.simulateMode === "manual" ? device.simulateFlightNumbers : undefined,
  );
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    let isCanceled = false;
    if (flightNums) {
      storeDevice(getUserId(), deviceId, {
        discPresetId: "",
        simulateFlightNumbers: flightNums,
        simulateMode: "manual",
      });
    } else {
      if (device?.simulateMode === "manual") {
        storeDevice(getUserId(), deviceId, { simulateMode: "auto" });
      }
    }
    return () => {
      isCanceled = true;
    };
  }, [deviceId, flightNums, device?.simulateMode]);

  const handleClick = (event) => {
    const value = event.target.value;
    if (value === "auto") {
      setFlightNums(undefined);
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
      if (!flightNums) {
        setFlightNums(device?.simulateFlightNumbers || DEFAULT_DISC_STATE);
      }
    }
  };

  const { isLiveAccount } = useGlobal();
  return (
    <PopupState variant="popover" popupId="flightNumberPopover">
      {(popupState) => (
        <Stack direction="row" spacing={4}>
          <ToggleButtonGroup
            value={flightNums ? "manual" : "auto"}
            aria-label="flight number chooser"
            exclusive
            size="small"
            color="primary"
            onClick={handleClick}
            // onChange={handleClick}
          >
            {isLiveAccount && (
              <ToggleButton
                value="auto"
                aria-label="auto"
                // add hover text
                title="Auto mode chooses the farthest flying from a selection of discs."
              >
                Auto
              </ToggleButton>
            )}
            <ToggleButton
              value="manual"
              aria-label="manual"
              {...bindTrigger(popupState)}
              title="Choose specific flight numbers."
            >
              {isLiveAccount ? "Manual" : "Flight Numbers"}
            </ToggleButton>
          </ToggleButtonGroup>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <FlightNumberSliders
              flight_numbers={flightNums ?? DEFAULT_DISC_STATE}
              setFlightNumbers={setFlightNums}
            />
            <FlightNumberBoxes flight_numbers={flightNums ?? DEFAULT_DISC_STATE} />
          </Popover>
        </Stack>
      )}
    </PopupState>
  );
}

export default function DiscDetails(props: Props) {
  const [user, userLoading, userError] = useAuthState(getAuth(firebaseApp));
  const { isBetaUser } = useRoleCheck(user);
  const [deviceName, setDeviceName] = useState<string | undefined>();
  const deferredDeviceName = useDeferredValue(deviceName);
  const [discPresets, presetsLoading, discPresetsError] = useCollectionData(
    getUserDiscPresets(getUserId(user)),
  );
  const notify = useNotify();
  const { deviceId } = props;
  const docId = useMemo(() => {
    const uid = getUserId(user);
    const devicePath = "/users/" + uid + "/devices/" + deviceId;
    return doc(getFirestore(firebaseApp), devicePath);
  }, [user]);

  const [value, isLoading, error] = useDocument(docId);

  useEffect(() => {
    if (deferredDeviceName !== undefined) {
      const toStore = { name: deferredDeviceName };
      void storeDevice(getUserId(user), deviceId, toStore);
    }
  }, [deferredDeviceName, deviceId, user]);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeviceName(event.target.value);
  };

  const handleNameBlur = () => {
    if (deviceName) {
      void storeDevice(getUserId(user), deviceId, { name: deviceName });
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.currentTarget.blur();
    }
  };

  const handleDiscPresetChange = (event: SelectChangeEvent) => {
    const discPresetId = event.target.value;
    const discPreset = discPresets?.find((preset) => preset.id === discPresetId);
    if (discPreset) {
      storeDevice(getUserId(user), deviceId, {
        discPresetId,
        simulateFlightNumbers: discPreset.flightNumbers,
        simulateMode: "manual",
      });
      notify("success", "Disc preset updated");
    }
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const device: Device = value?.data();
  const loadedName = device?.name;
  // Let loadedColor = device?.color || '';
  return !value ? null : (
    <Stack direction={"row"} alignItems={"center"} gap={2}>
      <Stack direction={"row"} alignItems={"center"} display={{ mobile: "none", lg: "flex" }}>
        <ColorPicker deviceId={deviceId} device={device} />
        <TextField
          fullWidth
          value={deviceName ?? loadedName}
          onChange={handleNameChange}
          onBlur={handleNameBlur}
          onKeyDown={handleKeyDown}
          inputProps={{ maxLength: 18 }}
          placeholder="TechDisc Name"
          title="Change the name of the connected TechDisc."
          size="small"
          sx={{
            width: "fit-content",
            mr: 1,
            color: device?.color ?? "primary.main",
            "&:hover": {
              bgcolor: "grey.100",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
        />
      </Stack>
      {device && (
        <>
          <FlightNumberButton device={device} deviceId={deviceId} />
          {isBetaUser && (
            <DiscPresetSelect
              onChange={handleDiscPresetChange}
              device={device}
              discPresets={discPresets}
            />
          )}
        </>
      )}
    </Stack>
  );
}
