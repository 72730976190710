import * as React from "react";
import { getAnalytics, logEvent } from "firebase/analytics";
import { uploadBytes, UploadMetadata } from "firebase/storage";
import { AnchorButton } from "@blueprintjs/core";
import {
  downloadBlob,
  getDeviceUidQueryParam,
  getQueryMap,
  getThrowIdQueryParam,
} from "./queryUtils";
import {
  DEVICES_USER_IDS,
  getTrueUserId,
  getUserId,
  POWER_USER_ID,
  POWER_USER_IDS,
  storeBuildDevice,
  storeCalibrationData,
  storeDevice,
  storeSummary,
  UNKNOWN_USER_ID,
} from "./summaryUtils";
import NoSleep from "nosleep.js";
import { renderDebugOrientation } from "./3dmini";
// @ts-ignore
import * as CRC32 from "crc-32";
import Account from "./dashboard/Account";
import { getStorageRef } from "./dashboard/dashboardUtils";
import { getAuth, User } from "firebase/auth";
import { firebaseApp } from "./firebaseConfig";
import DiscDetails from "./dashboard/DiscDetails";
import { Timestamp } from "firebase/firestore";
import { BuildDevice, DeviceCalibration } from "./model/device";
import { BuildWrapper } from "./build/BuildDashboard";
import {
  AccelCalibrateKey,
  AccelerometerCalibrationReadings,
  CalibrationValues,
  DeviceReading,
  DeviceReadings,
  Vector6,
} from "./model/calibrate";
import { ThrowUploadMetadata } from "./model/throwSummary";
import {
  AppBar,
  Box,
  Button,
  Divider,
  Icon,
  IconButton,
  Link,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NavigationMenu from "./components/layout/NavigationMenu";
import {
  Battery0BarTwoTone,
  Battery20,
  Battery30,
  Battery50,
  Battery60,
  Battery80,
  Battery90,
  BatteryFull,
} from "@mui/icons-material";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  bleBatteryLevelChar,
  bleBatteryService,
  bleNusCharRXUUID,
  bleNusCharTXUUID,
  bleNusServiceUUID,
  calibrateAccel0Uuid,
  calibrateAccel1Uuid,
  calibrateAccel2Uuid,
  calibrateDebugNumbers,
  calibrateDebugVectors,
  calibrateDeviceUid,
  calibrateErrorRx,
  calibrateGyroUuid,
  calibrateMacUuid,
  calibrateServiceUuid,
  calibrationKeyNames,
  CRC_SKIP_BYTES,
  cyOtaUpgradeServiceUuid,
  DATA_LEN_V5,
  DATA_LEN_V6,
  DELAY_MS_BEFORE_FETCH_FLIGHT,
  deviceInfoService,
  isCalibrationReady,
  MAX_FORMAT_VERSION,
  MAX_HARDWARE_VERSION,
  NUM_POINTS,
  sensorsGravityStandard,
  softwareRevision,
  VERSION_8K_DPS,
  VERSION_CRC,
  VERSION_MAG,
  VERSION_START_Q,
} from "./bleConsts";
import ShareButton from "./components/ShareButton";
import useNotify from "./hooks/useNotify";
import TagManager from "./components/TagManager";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Quaternion, Vector3 } from "three";

export interface BleConnectionProps {
  // callback when the id changes
  onDeviceIdChange?(value: string | undefined): void;
  isManufacturing?: boolean;
  hideConnectButton?: boolean;
  notify?: ReturnType<typeof useNotify>;
  shareEnabled?: boolean;
  getLatestThrowId?(): string | undefined;
  getAnalysisSetId?(): string | undefined | null;
  isDeviceBuild?: boolean;
  getLockedTags?(): string[] | undefined;
}

interface State {
  connected: boolean;
  bleDevice: any;
  batteryPercent: number;
  downloadFile: boolean;
  calibrating: boolean;
  singleMeasureCalibrate: boolean;
  calibrationReadings: DeviceReadings;
  lastReading: DeviceReading | undefined;
  lastQ?: Quaternion | undefined;
  deviceName?: string;
  user?: User | null;
  userLoading: boolean;
  uid?: string;
  mac?: string;
  softwareRev?: string;
  menuRef: HTMLElement;
}

const IDENTITY_CALIBRATE: Vector6 = [1, 1, 1, 0, 0, 0];
export const queryMap = getQueryMap();
export const calibrateEnabled = queryMap.has("calibrate");

export class BleConnection extends React.Component<BleConnectionProps, State> {
  constructor(props: BleConnectionProps) {
    super(props);
    this.state = {
      user: null,
      userLoading: true,
      connected: false,
      bleDevice: null,
      batteryPercent: -1,
      downloadFile: false,
      calibrating: false,
      singleMeasureCalibrate: false,
      calibrationReadings: {
        accel0: {},
        accel1: {},
        accel2: {},
        gyro: {},
        mag: {},
      },
    };
  }

  getHideConnectionButton(): boolean {
    const user = getAuth(firebaseApp)?.currentUser;
    return !!(
      this.props.hideConnectButton ||
      getDeviceUidQueryParam() ||
      getThrowIdQueryParam() ||
      !user
    );
  }

  componentDidMount(): void {
    const auth = getAuth(firebaseApp);

    auth.onAuthStateChanged((user) => {
      this.setState({ user, userLoading: false });
    });
  }

  bleDevice: any = null;
  bleServer: any = null;
  rxCharacteristic: any = null;
  deviceId: string | undefined = queryMap.get("deviceId");
  deviceUid: string | undefined = undefined;
  deviceSoftware: string | undefined = undefined;

  connected: boolean = false;
  lastDisconnect: number = 0;
  buf = new ArrayBuffer(DATA_LEN_V6 + 4);
  index = 0;
  lastStartMillis = 0;
  timeSinceThrowMs = 0;
  lastTransferMillis = 0;
  view = new DataView(this.buf);
  calibrateFetchTimer: NodeJS.Timeout | null = null;
  lastUpload: number | null = null;
  lastUploadCrc: number | null = null;
  transferFormatVersion: number = 2;
  transferFlightType: number = 0;
  downloadingFlight: NodeJS.Timeout | null = null;
  noSleep = new NoSleep();
  debugNumbers: DataView | null = null;
  debugVectors: DataView | null = null;
  debugNumbersChar?: BluetoothRemoteGATTCharacteristic;
  debugVectorsChar?: BluetoothRemoteGATTCharacteristic;
  errorLogCount: number = 0;
  nacking: boolean = false;

  updateBatteryState(level: number) {
    const alpha = 1.0 / 10.0;
    const alpha2 = 1.0 / 15.0;
    this.setState((old) => {
      let avg = old.batteryPercent;
      if (avg < 0) {
        return { batteryPercent: level };
      }
      avg = Math.floor(avg) * alpha + avg * (1 - alpha);
      avg = level * alpha2 + avg * (1 - alpha2);
      return { batteryPercent: avg };
    });
  }

  downloadFileToggle() {
    this.setState((prevState: State, props: any) => {
      return { downloadFile: !prevState.downloadFile };
    });
  }

  singleMeasureToggle() {
    this.setState((prevState: State, props: any) => {
      // if (this.state.singleMeasureCalibrate) {
      //   this.nusSendString("calibrate");
      // } else {
      //   this.nusSendString("orientation");
      // }
      return {
        singleMeasureCalibrate: !prevState.singleMeasureCalibrate,
      };
    });
  }

  connectionToggle() {
    // should be present on all chromium browsers
    if (!window.chrome) {
      return this.props.notify?.(
        "info",
        <div>
          At this time only{" "}
          <Link color="#fff" href="https://www.google.com/chrome/dr/download/" target="_blank">
            Chrome
          </Link>
          {", "}
          Edge, or other Chromium browsers support Bluetooth connections.
          <br />
          If you are on iOS, download our app from the{" "}
          <Link
            color="#fff"
            href="itms-apps://apps.apple.com/us/app/techdisc/id6450316226"
            target="_blank"
          >
            App Store
          </Link>{" "}
          to support Bluetooth connection.
        </div>,
      );
    }
    if (this.connected || this.bleDevice) {
      if (this.props.isDeviceBuild && this.props.isManufacturing) {
        this.disconnect();
        window.location.reload();
      } else {
        this.disconnect();
      }
    } else {
      if (getTrueUserId() === UNKNOWN_USER_ID) {
        this.props.notify?.("error", "Please log in to connect to a TechDisc.");
        return;
      }
      this.connect();
      this.noSleep.enable();
    }
  }

  updateConnButtonState() {
    this.setState((prevState: State, props: any) => {
      const newState: State = { ...prevState };
      newState.connected = this.connected;
      newState.bleDevice = this.bleDevice;
      newState.uid = this.deviceUid;
      newState.mac = this.deviceId;
      newState.softwareRev = this.deviceSoftware;
      if (!this.connected) {
        newState.calibrationReadings = {
          accel0: {},
          accel1: {},
          accel2: {},
          gyro: {},
          mag: {},
        };
      }
      return newState;
    });
  }

  renderDiscDetails() {
    if (!this.bleDevice && !this.deviceId) {
      return null;
    }
    if (!this.deviceId) {
      return null;
    }
    const deviceId = this.getDeviceId();
    return <DiscDetails deviceId={deviceId} />;
  }

  sendUpgradeCommand() {
    this.nusSendString("upgrade");
  }

  deleteCalibration() {
    const values: CalibrationValues = {
      accel0: IDENTITY_CALIBRATE,
      accel1: IDENTITY_CALIBRATE,
      accel2: IDENTITY_CALIBRATE,
      gyro: IDENTITY_CALIBRATE,
      mag: IDENTITY_CALIBRATE,
    };
    this.storeCalibrationValues(values);
  }

  handleClose = () => {
    this.setState({ menuRef: null });
  };

  render() {
    let text: string;
    let intentVal: "primary" | "warning" | "outlined";

    if (this.connected) {
      text = this.props.isDeviceBuild && this.props.isManufacturing ? "Reset" : "Disconnect";
      intentVal = "outlined";
    } else {
      if (this.bleDevice !== null) {
        text = "Disconnect";
        intentVal = "warning";
      } else {
        text = "Connect";
        intentVal = "primary";
      }
    }
    const connectButton = (
      <Button
        id="clientConnectButton"
        size="small"
        onClick={() => this.connectionToggle()}
        variant={intentVal}
        sx={{ textTransform: "none", width: "100px" }}
      >
        {text}
      </Button>
    );
    const downloadButton = (
      <Button id="downloadButton" onClick={() => this.download()}>
        Download
      </Button>
    );
    const upgradeButton = (
      <Button id="upgradeButton" onClick={() => this.sendUpgradeCommand()}>
        Upgrade
      </Button>
    );
    const fullBlastButton = (
      <Button id="fullBlastButton" onClick={() => this.nusSendString("F")}>
        Full Blast
      </Button>
    );
    const deleteCalibrationButton = (
      <Button id="deleteCalibrationButton" onClick={() => this.deleteCalibration()}>
        Remove Calibration
      </Button>
    );
    const flightButton = (
      <Button id="flightButton" onClick={() => this.downloadFlight()}>
        Flight
      </Button>
    );
    const batteryButton = (
      <Stack direction={"row"} alignItems={"center"}>
        {this.state.batteryPercent > 95 ? (
          <BatteryFull color="success" />
        ) : this.state.batteryPercent > 90 ? (
          <Battery90 color="success" />
        ) : this.state.batteryPercent > 80 ? (
          <Battery80 color="success" />
        ) : this.state.batteryPercent > 60 ? (
          <Battery60 color="success" />
        ) : this.state.batteryPercent > 50 ? (
          <Battery50 color="success" />
        ) : this.state.batteryPercent > 30 ? (
          <Battery30 color="warning" />
        ) : this.state.batteryPercent > 0 ? (
          <Battery20 color="warning" />
        ) : (
          <Battery0BarTwoTone color="error" />
        )}
        <Typography variant="button" color="grey.800" sx={{ userSelect: "none" }}>
          {this.state.batteryPercent.toFixed(0) + "%"}
        </Typography>
      </Stack>
    );
    const shouldDownload = (
      <Button id="downloadFileToggleButton" onClick={() => this.downloadFileToggle()}>
        {this.state.downloadFile ? "switch to Upload" : "switch to Download"}
      </Button>
    );
    const singleCalToggle = (
      <Button id="singleMeasureCalibrateToggle" onClick={() => this.singleMeasureToggle()}>
        {this.state.singleMeasureCalibrate ? "Single" : "Multi"}
      </Button>
    );
    const navbar = (
      <>
        <Box sx={{ flex: "0 1 auto", height: "48px" }}>
          <AppBar
            position="fixed"
            elevation={0}
            sx={(theme) => ({ bgcolor: theme.palette.common.white })}
          >
            <Toolbar
              variant="dense"
              sx={{
                px: 1,
                display: "flex",
                justifyContent: "space-between",
                borderBottom: (theme) => `1px solid ${theme.palette.grey[400]}`,
                // make scrollable for mobile
                overflowX: "scroll",
                "&::-webkit-scrollbar": { display: "none" },
                scrollbarWidth: "none",
              }}
              disableGutters
            >
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <Box
                  component={Link}
                  href="/"
                  color="inherit"
                  underline="none"
                  sx={{ outline: "none" }}
                >
                  <Box
                    component="img"
                    src="https://storage.googleapis.com/techdisc-cdn/logo_assets/TechDisc_Logo_Tagline_dark.svg"
                    height="42px"
                    alt="TechDisc Logo"
                    sx={(theme) => ({
                      [theme.breakpoints.down("md")]: { display: "none" },
                    })}
                  />
                  <Box
                    component="img"
                    src="https://storage.googleapis.com/techdisc-cdn/logo_assets/TechDisc_Logo_dark.svg"
                    height="42px"
                    alt="TechDisc Logo"
                    sx={(theme) => ({ [theme.breakpoints.up("md")]: { display: "none" } })}
                  />
                </Box>
                {!this.getHideConnectionButton() && (
                  <>
                    <Divider orientation="vertical" flexItem sx={{ my: 1 }} />
                    <Stack direction={"row"} gap={1}>
                      {connectButton}
                      {this.state.connected && this.state.batteryPercent != -1 && batteryButton}
                    </Stack>
                    {this.state.connected && (
                      <>
                        <Divider orientation="vertical" flexItem sx={{ my: 1 }} />
                        {this.renderDiscDetails()}
                      </>
                    )}
                  </>
                )}
              </Stack>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                {getUserId() === POWER_USER_ID &&
                  this.state.connected &&
                  !this.state.calibrating &&
                  flightButton}
                {this.state.connected &&
                  !this.state.calibrating &&
                  calibrateEnabled &&
                  downloadButton}
                {this.state.connected &&
                  !this.state.calibrating &&
                  calibrateEnabled &&
                  upgradeButton}
                {this.state.connected &&
                  calibrateEnabled &&
                  POWER_USER_IDS.has(getTrueUserId()) &&
                  fullBlastButton}
                {this.state.connected &&
                  calibrateEnabled &&
                  POWER_USER_IDS.has(getTrueUserId()) &&
                  deleteCalibrationButton}
                {POWER_USER_IDS.get(getTrueUserId()) &&
                  calibrateEnabled &&
                  this.state.calibrating &&
                  singleCalToggle}

                {this.state.connected && !this.props.isDeviceBuild && this.renderCalibrateButton()}
                {this.state.connected &&
                  !this.props.isDeviceBuild &&
                  this.state.calibrating &&
                  this.renderCalibrateButtons()}
                {DEVICES_USER_IDS.has(getTrueUserId()) && (
                  <AnchorButton href={"/devices"} minimal={true} icon="database" text="Devices" />
                )}
                {this.props.shareEnabled && (
                  <>
                    <ShareButton latestThrowId={this.props.getLatestThrowId?.()} />
                    <Divider orientation="vertical" flexItem sx={{ my: 1 }} />
                  </>
                )}
                <Account />
                <Divider orientation="vertical" flexItem sx={{ my: 1 }} />
                <IconButton
                  onClick={(event: React.MouseEvent<HTMLElement>) =>
                    this.setState({ menuRef: event.currentTarget })
                  }
                >
                  <Icon component={MenuIcon} />
                </IconButton>
                <NavigationMenu onClose={this.handleClose} menuRef={this.state.menuRef} />
              </Stack>
            </Toolbar>
          </AppBar>
        </Box>
        {this.state.connected && !this.props.isDeviceBuild && this.renderLastReading()}
        <canvas
          id={"mini3d"}
          width={800}
          height={800}
          hidden={!this.state.connected || !this.state.lastReading || this.props.isDeviceBuild}
        />
        {this.props.isDeviceBuild && (
          <BuildWrapper
            deviceUid={this.state.uid ?? ""}
            mac={this.state.mac}
            softwareRev={this.state.softwareRev}
            lastReading={this.state.lastReading}
            readings={this.state.calibrationReadings}
            calibrateDirection={(key) => this.calibrateDirection(key)}
            calibrate={() => this.calibrate()}
            resendToDisc={(values) => this.storeCalibrationValues(values, false)}
            connected={this.state.connected}
            calibrating={this.state.calibrating}
            renderDebugOrientation={(canvas: Element) =>
              renderDebugOrientation(this.bleServer, canvas, (q) => this.setLastQ(q))
            }
            cancelCalibration={() => this.setCalibrationTimer(null)}
          />
        )}
      </>
    );

    return navbar;
  }

  renderCalibrateButton() {
    if (calibrateEnabled) {
      let disabled = false;
      let text = "Calibrate";
      if (this.state.calibrating) {
        text = "Save Calibration";
        disabled = !this.isCalibrationReady();
      }
      return (
        <Button id="calibrateButton" onClick={() => this.calibrate()} disabled={disabled}>
          {text}
        </Button>
      );
    }

    if (!POWER_USER_IDS.has(getTrueUserId())) {
      // We only want to show the debug button to power users
      return undefined;
    }

    return (
      <>
        <Button variant="secondary" id="orientationButton" onClick={() => this.startOrientation()}>
          Debug
        </Button>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ my: 1, display: { mobile: "none", lg: "flex" } }}
        />
      </>
    );
  }

  renderCalibrateButtons() {
    if (!calibrateEnabled) {
      return null;
    }
    if (this.state.singleMeasureCalibrate) {
      return [this.createCalibrateButton("z"), this.createCalibrateButton("z180")];
    }
    return calibrationKeyNames.map((key) => this.createCalibrateButton(key));
  }

  private createCalibrateButton(key: AccelCalibrateKey) {
    return (
      <Button
        id={"calibration_" + key}
        variant={this.state.calibrationReadings.accel0[key] ? "primary" : "secondary"}
        key={key}
        onClick={() => this.calibrateDirection(key)}
      >
        {key}
      </Button>
    );
  }

  renderLastReading() {
    if (!this.state.lastReading) {
      return null;
    }
    const {
      accel0,
      accel1,
      accel2,
      gyro,
      mag,
      missedSpi,
      loopNanos,
      sampleNanos,
      avgAccelNed,
      avgMagNed,
      samplesNoMotion,
      gravityCorrectMicroDeg,
      gravityCorrectMicroPercent,
    } = this.state.lastReading;
    const axiis = ["x", "y", "z"];
    const lastQ = this.state.lastQ;

    const rows = axiis.map((axis, index) => ({
      id: axis,
      axis,
      accel0: accel0[index],
      accel1: accel1[index],
      accel2: accel2[index],
      gyro: gyro[index],
      mag: mag?.[index],
      avgAccelNed: avgAccelNed?.[index],
      avgMagNed: avgMagNed?.[index],
      missedSpi: missedSpi,
      loopNanos,
      sampleNanos,
      samplesNoMotion,
      gravityCorrectMicroDeg,
      gravityCorrectMicroPercent,
      bearing: this.getBearing(lastQ ?? new Quaternion(0, 0, 0, 0)),
    }));

    const columns: GridColDef<
      Partial<
        Omit<
          DeviceReading,
          "accel0" | "accel1" | "accel2" | "gyro" | "mag" | "avgAccelNed" | "avgMagNed"
        > & {
          axis: string;
          accel0: number;
          accel1: number;
          accel2: number;
          gyro: number;
          mag: number;
          avgAccelNed: number;
          avgMagNed: number;
        }
      >
    >[] = [
      {
        field: "axis",
        headerName: "Axis",
        width: 100,
      },
      {
        field: "accel0",
        headerName: "Accel0",
        width: 100,
      },
      {
        field: "accel1",
        headerName: "Accel1",
        width: 100,
      },
      {
        field: "accel2",
        headerName: "Accel2",
        width: 100,
      },
      {
        field: "gyro",
        headerName: "Gyro",
        width: 100,
      },
      {
        field: "mag",
        headerName: "Mag",
        width: 100,
      },
      {
        field: "samplesNoMotion",
        headerName: "No Motion",
        width: 100,
      },
      {
        field: "avgAccelNed",
        headerName: "Accel NED",
        width: 100,
      },
      {
        field: "avgMagNed",
        headerName: "Mag NED",
        width: 100,
      },
      {
        field: "missedSpi",
        headerName: "Missed SPI, nsCalc, nsGyro",
        width: 100,
        valueGetter(_value, row) {
          return row.sampleNanos
            ? row.axis === "x"
              ? row.missedSpi
              : row.axis === "y"
                ? row.loopNanos
                : row.sampleNanos
            : "upgrade needed";
        },
      },
      {
        field: "gravityCorrectMicroDeg",
        headerName: "Gravity Correct Micro Deg, %, bearing Z",
        valueGetter(_value, row) {
          return row.sampleNanos
            ? row.axis === "x"
              ? ((row.gravityCorrectMicroDeg ?? 0.0) / 1000.0).toFixed(1)
              : row.axis === "y"
                ? ((row.gravityCorrectMicroPercent ?? 0.0) * 100.0e-6).toFixed(2)
                : row.bearing.toFixed(1)
            : "upgrade needed";
        },
        width: 100,
      },
    ];

    return <DataGrid rows={rows} columns={columns} />;
  }

  getBearing(q: Quaternion): number {
    const vector = new Vector3(0, 0, -1);
    vector.applyQuaternion(q);
    const minusAndPlus = (Math.atan2(vector.y, vector.x) * 180) / Math.PI;
    if (minusAndPlus < 0) {
      return 360 + minusAndPlus;
    }
    return minusAndPlus;
  }

  normalize(v: Vector3): Vector3 {
    const norm = Math.sqrt(v[0] * v[0] + v[1] * v[1] + v[2] * v[2]);
    if (norm < 1e-6) {
      return v;
    }
    return [v[0] / norm, v[1] / norm, v[2] / norm];
  }

  connect() {
    if (!navigator.bluetooth) {
      this.props.notify?.(
        "info",
        <div>
          WebBluetooth API is not available.
          <br />
          Please make sure the Web Bluetooth flag is enabled.
        </div>,
      );
      return;
    }
    console.log("Requesting Bluetooth Device...");

    const prefix = "TechDisc";

    // @ts-ignore
    const promise = navigator.bluetooth.requestDevice({
      //filters: [{services: []}]
      filters: [{ namePrefix: [prefix] }],
      optionalServices: [
        bleNusServiceUUID,
        cyOtaUpgradeServiceUuid,
        deviceInfoService,
        bleBatteryService,
        calibrateServiceUuid,
      ],
      acceptAllDevices: false,
    });
    this.connectDeviceAsync(promise);
  }

  readonly onAdvertisementListener = (event: any) => this.onAdvertisement(event);

  onAdvertisement(event: any) {
    console.log("Advertising Watch Found");
    event.device.removeEventListener("advertisementreceived", this.onAdvertisementListener);
    if (this.bleDevice !== null) {
      this.bleDevice.removeEventListener("advertisementreceived", this.onAdvertisementListener);
      if (!this.connected) {
        this.connectDeviceAsync(Promise.resolve(event.device));
      }
    }
  }

  attemptReconnect() {
    if (this.bleDevice == null || this.connected) {
      this.lastDisconnect = 0;
      return;
    }

    if (this.lastDisconnect === 0) {
      // we are starting our search for a new connection
      this.lastDisconnect = Date.now();
    } else if (Date.now() - this.lastDisconnect > 60 * 60 * 1000) {
      // we have been disconnected for 60 minutes, so we give up reconnecting
      console.warn("Searching timed out. Disconnecting.");
      this.props.notify?.("error", "Connection lost: " + this.bleDevice.name);
      this.disconnect();
      this.updateConnButtonState();
    }

    if (this.bleDevice == null || this.connected) {
      this.lastDisconnect = 0;
      return;
    }

    const millis = Date.now() - this.lastDisconnect;
    console.log("Attempt Reconnect from timer. Disconnected for: " + millis + "ms");

    this.connectDeviceAsync(Promise.resolve(this.bleDevice));
  }

  // async doUpgrade(server: BluetoothRemoteGATTServer) {
  //   const service: BluetoothRemoteGATTService = await server.getPrimaryService(
  //     cyOtaUpgradeServiceUuid
  //   );
  //   const characteristic: BluetoothRemoteGATTCharacteristic = await service.getCharacteristic(
  //     cyOtaUpgradeCommandUuid
  //   );
  //   // await beginUpgrade(characteristic);
  // }

  async connectDeviceAsync(devicePromise: Promise<any>) {
    const device = await devicePromise;

    try {
      console.log("Found " + device.name);
      console.log("Connecting to GATT Server...");
      this.bleDevice = device;
      const serverPromise = device.gatt.connect();
      // figure out how to wait for promise
      let loopCount = 0;
      while (!device.gatt.connected) {
        const result = await Promise.race([new Promise((r) => setTimeout(r, 500)), serverPromise]);
        if (result) {
          console.log(result);
        }
        if (loopCount++ > 20) {
          throw "Device no longer in range to connect";
        }
      }
      const server = await serverPromise;
      device.addEventListener("gattserverdisconnected", () => this.onDisconnected()); // TODO seems to be bubbling
      if (this.bleDevice == null) {
        server.disconnect();
        throw "Device no longer connected";
      }
      console.log("Locate NUS service");
      const nusServicePromise: BluetoothRemoteGATTService =
        server.getPrimaryService(bleNusServiceUUID);
      const raceResult = await Promise.race([
        new Promise((r) => setTimeout(r, 10_000)),
        nusServicePromise,
      ]);
      if (!raceResult) {
        console.error("NUS service not found.");
        throw "Device no longer in range to connect";
      }
      const nusService: BluetoothRemoteGATTService = await nusServicePromise;
      console.log("Found NUS service: " + nusService.uuid);
      const rxCharacteristic = await nusService.getCharacteristic(bleNusCharRXUUID);
      console.log("Found RX characteristic");
      const txCharacteristic = await nusService.getCharacteristic(bleNusCharTXUUID);
      console.log("Found TX characteristic");

      const calibrateService = await server.getPrimaryService(calibrateServiceUuid);
      let deviceUid = undefined;
      let deviceId = undefined;
      try {
        const macChar = await calibrateService.getCharacteristic(calibrateMacUuid);
        const macValue: DataView = await macChar.readValue();
        deviceId = Array(6)
          .fill(0)
          .map((_, i) => {
            const byte = macValue.getUint8(i);
            return ("0" + (byte & 0xff).toString(16)).slice(-2);
          })
          .join(":");

        try {
          const uidChar = await calibrateService.getCharacteristic(calibrateDeviceUid);
          const uidValue: DataView = await uidChar.readValue();
          deviceUid = Array(8)
            .fill(0)
            .map((_, i) => {
              const byte = uidValue.getUint8(i);
              return ("0" + (byte & 0xff).toString(16)).slice(-2);
            })
            .join(":");
        } catch (error) {
          // console.log("old device with no uid");
        }
      } catch (error) {
        console.log(
          "old devices do not support ids across devices, please update to the lastest firmware",
        );
      }

      try {
        const infoService = await server.getPrimaryService(deviceInfoService);
        const softwareRevChar = await infoService.getCharacteristic(softwareRevision);
        const softwareRev: DataView = await softwareRevChar.readValue();
        const decoder = new TextDecoder();
        const softwareRevString = decoder.decode(softwareRev);
        this.deviceSoftware = softwareRevString;
      } catch (error) {
        console.log("no software rev found");
      }

      this.index = 0;

      txCharacteristic.addEventListener("characteristicvaluechanged", (event: any) =>
        this.handleNotifications(event),
      );
      await txCharacteristic.startNotifications();
      console.log("Notifications started");

      this.props.notify?.("info", "Connected to " + this.bleDevice.name);

      if (this.bleDevice === device) {
        this.bleServer = server;
        this.rxCharacteristic = rxCharacteristic;
        this.connected = true;
        this.lastDisconnect = 0;
        this.deviceId = deviceId;
        this.deviceUid = deviceUid;
        if (this.props.onDeviceIdChange) {
          this.props.onDeviceIdChange(deviceId);
        }
        if (this.state.calibrating) {
          const map = getQueryMap();
          if (calibrateEnabled || this.props.isDeviceBuild) {
            // when calibrating we always want the device in calibrate mode
            this.nusSendString("calibrate");
          } else {
            // if we reconnect, we can hit the debug button again
            // this.nusSendString("orientation");
          }
        } else {
          // pull data if reconnecting
          // this.fetch();
        }
      }

      this.updateConnButtonState();

      const batteryService = await server.getPrimaryService(bleBatteryService);
      const batteryCharacteristic = await batteryService.getCharacteristic(bleBatteryLevelChar);

      const batteryValue: DataView = await batteryCharacteristic.readValue();
      this.handleBatteryUpdate(batteryValue);
      batteryCharacteristic.addEventListener("characteristicvaluechanged", (event: any) =>
        this.handleBatteryUpdate(event.target.value),
      );
      await batteryCharacteristic.startNotifications();

      try {
        const errLogChar = await calibrateService.getCharacteristic(calibrateErrorRx);
        const errorLogValue: DataView = await errLogChar.readValue();
        this.handleErrorLogUpdate(errorLogValue);
        errLogChar.addEventListener("characteristicvaluechanged", (event: any) =>
          this.handleErrorLogUpdate(event.target.value),
        );
        await errLogChar.startNotifications();
      } catch (error) {
        console.info("old devices do not support error logs", error);
      }
      try {
        if (this.props.isDeviceBuild) {
          this.setLastReading(await this.fetchCalibrationValues());
        }
      } catch (error) {
        console.error("failed to register for device data", error);
      }

      if (this.lastUpload) {
        setTimeout(() => this.downloadFlight(), 1_000);
      }
    } catch (error) {
      console.log("" + error);
      this.connected = false;
      this.updateConnButtonState();
      if (this.bleDevice) {
        this.bleDevice.gatt.disconnect();
        setTimeout(() => this.attemptReconnect(), 1_000);
      }
    }
  }

  handleErrorLogUpdate(value: DataView) {
    const uint8Array = new Uint8Array(value.buffer, value.byteOffset, value.byteLength);
    const textDecoder = new TextDecoder();
    const decodedString = textDecoder.decode(uint8Array);

    // DataView is a C style string terminated by a null character
    const string = decodedString.split("\x00")[0];
    if (string.length === 0) {
      return;
    }

    console.error("ERROR LOG FROM TECHDISC: " + string);
    if (this.errorLogCount++ < 10) {
      logEvent(getAnalytics(firebaseApp), "device_error", {
        error: string,
        ...this.getCustomMetadata(),
      });
    }
  }

  private getCustomMetadata() {
    return {
      deviceId: this.deviceId,
      deviceUid: this.deviceUid,
      userId: getTrueUserId(),
      deviceSoftware: this.deviceSoftware,
      client: "web",
    };
  }

  disconnect() {
    this.setState({
      batteryPercent: -1,
      deviceName: undefined,
      connected: false,
      bleDevice: null,
    });
    if (!this.bleDevice) {
      console.log("No Bluetooth Device connected...");
      return;
    }
    console.log("Disconnecting from Bluetooth Device...");
    const device = this.bleDevice;
    this.connected = false;
    this.bleDevice = null;
    this.bleServer = null;
    this.deviceId = undefined;
    this.deviceUid = undefined;
    this.deviceSoftware = undefined;
    if (this.props.onDeviceIdChange) {
      this.props.onDeviceIdChange(undefined);
    }
    this.index = 0;
    device.gatt.disconnect();
  }

  onDisconnected() {
    const wasConnected = this.connected;
    this.connected = false;
    this.updateConnButtonState();
    if (this.bleDevice && wasConnected) {
      this.props.notify?.(
        "error",
        this.bleDevice.name + " has lost connection, now attempting to reconnect.",
      );
      this.connectDeviceAsync(Promise.resolve(this.bleDevice));
    } else if (!this.bleDevice) {
      // if you just click disconnect on screen you'll get here
      this.props.notify?.("info", "Bluetooth disconnected.");
    }
  }

  handleBatteryUpdate(value: DataView) {
    const batteryLevel = value.getUint8(0);
    this.updateBatteryState(batteryLevel);
    // console.log('> Battery Level is ' + batteryLevel + '%');
    // this.setState({
    //   batteryPercent: batteryLevel,
    // });
  }

  handleNotifications(event: any) {
    const value = event.target.value;
    const now = Date.now();

    let cutoffMillis = 2500;
    if (this.downloadingFlight) {
      cutoffMillis = 2500;
    }

    if (this.transferFormatVersion <= VERSION_START_Q) {
      // This is an old device.
      cutoffMillis = 5000;
    }

    // if (this.index !== 0 && now - this.lastStartMillis > cutoffMillis) {
    if (this.index !== 0 && now - this.lastTransferMillis > cutoffMillis) {
      console.log("Starting a new transfer due to timeout. index = 0", this.index);
      this.index = 0;
    }

    this.lastTransferMillis = now;
    if (this.index == 0) {
      this.lastStartMillis = now;
    }

    const DATA_LEN = this.transferFormatVersion >= VERSION_MAG ? DATA_LEN_V6 : DATA_LEN_V5;
    let bytesToTransfer = DATA_LEN;

    bytesToTransfer += 4;

    if (value.byteLength === 4 && this.index !== DATA_LEN && this.index + 4 !== DATA_LEN) {
      // this is an out of place CRC, next notification will be a new transfer
      console.log("> Out of place CRC: " + this.index);
      this.index = 0;
      this.nacking = false;
      return;
    }

    for (let i = 0; i < value.byteLength; i++) {
      if (this.index >= bytesToTransfer) {
        console.log("> Extra Data. bytes: " + value.byteLength);

        // sending a nack will have the device begin a new transfer
        // if (this.transferFormatVersion >= VERSION_8K_DPS) {
        if (!this.nacking) {
          this.nusSendString("nack");
          this.nacking = true;
        }
        // }
        return;
      }
      this.view.setUint8(this.index++, value.getUint8(i));
    }

    if (this.index == value.byteLength) {
      // This is the first batch of data
      const formatVersion = this.view.getUint8(0);
      const hardwareVersion = this.view.getUint8(1);
      const startIndex = this.view.getUint16(2, true);
      const timeSinceThrowMs = this.view.getUint16(4, true) * 10;
      const flightType = this.view.getUint8(6);
      const numPoints = this.view.getUint8(7) * 100;
      if (
        formatVersion > MAX_FORMAT_VERSION ||
        hardwareVersion > MAX_HARDWARE_VERSION ||
        startIndex >= NUM_POINTS ||
        (formatVersion >= VERSION_8K_DPS && numPoints != NUM_POINTS)
      ) {
        // if this batch starts with an invalid header, we just discard it
        console.log("> INVALID THROW DATA HEADER");
        if (!this.nacking) {
          this.nusSendString("nack");
          this.nacking = true;
        }
        this.index = bytesToTransfer;
        return;
      }

      this.transferFormatVersion = formatVersion;
      this.transferFlightType = flightType;
      this.timeSinceThrowMs = timeSinceThrowMs;
    }

    if (this.index == bytesToTransfer) {
      console.log("> Transfer Complete");
      const buf = this.buf.slice(0, DATA_LEN);
      const crc: number = CRC32.buf(new Uint8Array(buf, CRC_SKIP_BYTES));
      if (this.transferFormatVersion >= VERSION_CRC) {
        const passedCrc = this.view.getInt32(DATA_LEN, true);
        if (crc != passedCrc) {
          // crc is wrong
          console.log("> CRC is WRONG");
          this.index = 0;
          return;
        }
      }

      if (this.lastUploadCrc && crc == this.lastUploadCrc) {
        // data sent twice
        console.log("> Data sent twice. acking again.");
        this.nusSendString("ack");
        return;
      }

      const bleTransferMillis = Date.now() - this.lastStartMillis;

      console.log("> Transfer complete. Storing data...");
      this.lastUploadCrc = crc;
      let seconds = Math.floor(this.lastStartMillis / 1000);
      let suffix = "throw";
      const isThrow = this.transferFlightType === 0;
      if (!isThrow) {
        if (this.downloadingFlight && this.lastUpload) {
          seconds = this.lastUpload;
          suffix = "flight";
          clearInterval(this.downloadingFlight);
          this.downloadingFlight = null;
        } else {
          console.error("Recieved a flight when we weren't expecting one.");
          return;
        }
      }
      const throwRef = getStorageRef("/raw-throws/" + getUserId() + "/" + seconds + "." + suffix);
      if (this.state.downloadFile) {
        this.downloadFile(suffix, buf);
        this.nusSendString("ack");
        this.lastUpload = seconds;
        this.lastUploadCrc = crc;
        this.index = 0;
        this.nacking = false;
        if (!isThrow) {
          // Clear out last upload after we have fetched the flight.
          this.lastUpload = null;
        }
      } else {
        const deviceId = this.getDeviceId();
        const customMetadata: ThrowUploadMetadata = {
          deviceId,
          userId: getTrueUserId(),
          deviceBeginTransfer: this.lastStartMillis.toString(),
          client: "web",
        };
        if (this.deviceUid) {
          customMetadata.deviceUid = this.deviceUid;
        }
        if (this.deviceSoftware) {
          customMetadata.deviceSoftware = this.deviceSoftware;
        }
        const metadata: UploadMetadata = {
          customMetadata: customMetadata,
        };
        uploadBytes(throwRef, buf, metadata)
          .catch((error) => {
            console.error("Upload failed: " + error);
            logEvent(getAnalytics(firebaseApp), "upload_failed", {
              error,
              path: throwRef.fullPath,
              ...customMetadata,
            });
            this.props.notify?.("error", "Throw failed to store. Make sure internet is working.");
            throw error;
            // this.downloadFile(suffix, buf);
          })
          .then(async (snapshot) => {
            // The bytes were successfully uploaded,
            // We ack the transfer to the device and trigger a summarize
            // Also store the stub object with tags and uploadTime

            const ackPromise = this.nusSendString("ack");
            if (isThrow) {
              this.props.notify?.("info", "Throw uploaded.");

              httpsCallable(
                getFunctions(firebaseApp, "us-west1"),
                "summarize-throw-prod-http",
              )({
                bucket: "throw-log.appspot.com",
                name: "raw-throws/" + getUserId() + "/" + seconds + ".throw",
              }).catch((error) => {
                console.error("Manual http summarize failed: " + error);
                logEvent(getAnalytics(firebaseApp), "http_summarize_failed", {
                  error,
                  path: throwRef.fullPath,
                  ...customMetadata,
                });
              });
              const toStore = {
                uploadTime: Timestamp.now(),
                throwTime: Timestamp.fromMillis(this.lastStartMillis - this.timeSinceThrowMs),
              };
              const tags = TagManager.getLockedTags(getUserId());
              if (tags?.length) {
                // @ts-ignore
                toStore.tags = tags;
              }
              await storeSummary(getUserId(), seconds.toString(), toStore, customMetadata);
            }

            const uploadthrowMillis = Date.now() - this.lastStartMillis - bleTransferMillis;
            this.index = 0;
            this.lastUpload = seconds;
            if (!isThrow) {
              // Clear out last upload after we have fetched the flight.
              this.lastUpload = null;
              logEvent(getAnalytics(firebaseApp), "upload_flight", { ...customMetadata });
            } else {
              logEvent(getAnalytics(firebaseApp), "upload_throw", { ...customMetadata });
              logEvent(getAnalytics(firebaseApp), "throw_upload_latency", {
                ...customMetadata,
                bluetoothMillis: bleTransferMillis,
                uploadMillis: uploadthrowMillis,
              });
            }
            if (isThrow) {
              await ackPromise;
              await new Promise((r) => setTimeout(r, DELAY_MS_BEFORE_FETCH_FLIGHT));
              this.downloadFlight();
            }
          });
      }
    }
  }

  private downloadFile(suffix: string, buf: ArrayBuffer) {
    const fileName = "throw_" + Date.now() + "." + suffix;
    downloadBlob(fileName, buf);
  }

  async startOrientation() {
    logEvent(getAnalytics(firebaseApp), "debug_orientation", this.getCustomMetadata());
    await this.nusSendString("orientation");
    await new Promise((r) => setTimeout(r, 50));
    this.startCalibration();
  }

  private isCalibrationReady(): boolean {
    if (this.state.singleMeasureCalibrate) {
      return (
        this.state.calibrationReadings.accel0["z"] != null &&
        this.state.calibrationReadings.accel0["z180"] != null
      );
    }
    return isCalibrationReady(this.state.calibrationReadings);
  }

  async calibrate(): Promise<CalibrationValues | undefined> {
    const allPopulated = this.isCalibrationReady();
    if (allPopulated) {
      const values = this.computeCalibrationValues(this.state.calibrationReadings);
      await this.storeCalibrationValues(values);
      this.setCalibrationTimer(null);
      return values;
    } else {
      await this.nusSendString("calibrate");
      await new Promise((r) => setTimeout(r, 50));
      this.startCalibration();
      return undefined;
    }
  }

  private async storeCalibrationValues(values: CalibrationValues, storeToDb: boolean = true) {
    const size = 4 * 6 * (values.mag ? 5 : 4);
    const array = new Uint8Array(size);
    const view = new DataView(array.buffer);
    let offset = 0;
    offset = this.writeCalibrationValues(values.accel0, view, offset);
    offset = this.writeCalibrationValues(values.accel1, view, offset);
    offset = this.writeCalibrationValues(values.accel2, view, offset);
    offset = this.writeCalibrationValues(values.gyro, view, offset);
    if (values.mag) {
      offset = this.writeCalibrationValues(values.mag, view, offset);
    }
    try {
      await this.rxCharacteristic.writeValue(array);
      this.props.notify?.("info", "Sucessfully sent calibration data.");
    } catch (error) {
      console.error(error);
      this.props.notify?.("error", "Failed to send calibration data. Try again.");
    }
    if (storeToDb) {
      await this.storeCalibrationValuesToDb(values, this.state.calibrationReadings);
    }
  }

  private startCalibration() {
    this.setState({
      calibrating: true,
      calibrationReadings: {
        accel0: {},
        accel1: {},
        accel2: {},
        gyro: {},
      },
    });
    const interval = setInterval(() => this.populateLastReading(), 500);
    this.setCalibrationTimer(interval);
    if (!this.props.isDeviceBuild) {
      const canvas = document.querySelector("#mini3d");
      if (canvas) {
        renderDebugOrientation(this.bleServer, canvas, (q) => this.setLastQ(q)).catch((error) => {
          console.log("failed to animate debug", error);
        });
      }
    }
  }

  async populateLastReading() {
    try {
      const values = await this.fetchCalibrationValues();
      this.setLastReading(values);
    } catch (error) {
      console.log("failed to populate reading", error);
    }
  }

  setLastReading(reading: DeviceReading | undefined) {
    if (reading) {
      this.setState({ lastReading: reading });
    } else {
      this.setState({ calibrating: false, lastReading: reading });
    }
  }

  setLastQ(q: Quaternion | undefined) {
    this.setState({ lastQ: q });
  }

  setCalibrationTimer(newTimer: NodeJS.Timeout | null) {
    if (this.calibrateFetchTimer) {
      clearInterval(this.calibrateFetchTimer);
    }
    this.calibrateFetchTimer = newTimer;
    if (newTimer == null) {
      this.setLastReading(undefined);
      this.debugVectors = null;
      this.debugNumbers = null;
      this.debugNumbersChar?.stopNotifications();
      this.debugVectorsChar?.stopNotifications();
      this.debugNumbersChar = undefined;
      this.debugVectorsChar = undefined;
    }
  }

  writeCalibrationValues(values: Vector6, view: DataView, offset: number): number {
    for (let i = 0; i < values.length; i++) {
      view.setFloat32(offset + i * 4, values[i], true);
    }
    return offset + 6 * 4;
  }

  public async calibrateDirection(direction: AccelCalibrateKey) {
    try {
      this.setState((prevState: State, props: any) => {
        const values = prevState.lastReading;
        if (!values) {
          return prevState;
        }
        // TODO: verify that the disc is pointed in the correct direction before setting the state.
        const accel0 = { ...prevState.calibrationReadings.accel0 };
        const accel1 = { ...prevState.calibrationReadings.accel1 };
        const accel2 = { ...prevState.calibrationReadings.accel2 };
        const gyro = { ...prevState.calibrationReadings.gyro };
        const mag = { ...prevState.calibrationReadings.mag };
        const magNeg = { ...prevState.calibrationReadings.magNeg };
        accel0[direction] = values.accel0;
        gyro[direction] = values.gyro;
        mag[direction] = values.mag;
        magNeg[direction] = values.magNeg;
        const accel1Key = this.nextKey(direction);
        accel1[accel1Key] = values.accel1;
        const accel2Key = this.prevKey(direction);
        accel2[accel2Key] = values.accel2;
        const newReadings = {
          calibrationReadings: {
            accel0: accel0,
            accel1: accel1,
            accel2: accel2,
            gyro: gyro,
            mag: mag,
            magNeg: magNeg,
            lastReading: values,
          },
        };
        console.log(newReadings);
        return newReadings;
      });
    } catch (error) {
      console.log("Failed to read calibration data" + error);
      console.log(error);
    }
  }

  private async fetchCalibrationValues(): Promise<DeviceReading> {
    try {
      if (this.debugNumbers === null || this.debugVectors === null) {
        const service = await this.bleServer.getPrimaryService(calibrateServiceUuid);
        const debugNumbersChar = await service.getCharacteristic(calibrateDebugNumbers);
        const debugVectorsChar = await service.getCharacteristic(calibrateDebugVectors);
        if (debugNumbersChar && debugVectorsChar) {
          // this.setCalibrationTimer(null);
          await debugNumbersChar.addEventListener(
            "characteristicvaluechanged",
            (event: any) => (this.debugNumbers = event.target.value),
          );
          await debugVectorsChar.addEventListener("characteristicvaluechanged", (event: any) => {
            this.debugVectors = event.target.value;
            this.populateLastReading();
          });
          this.debugNumbers = await debugNumbersChar.readValue();
          this.debugVectors = await debugVectorsChar.readValue();
          this.debugVectorsChar = debugVectorsChar;
          this.debugNumbersChar = debugNumbersChar;
          await debugNumbersChar.startNotifications();
          await debugVectorsChar.startNotifications();
        }
      }
    } catch (error) {
      console.error(error);
      // some clients don't have these debug characteristics
    }

    // const accel1FloatValue: DataView = accel1FloatingChar.length
    //   ? await accel1FloatingChar[0].readValue()
    //   : new DataView(new ArrayBuffer(12));
    //
    // const accel2FloatValue: DataView = accel2FloatingChar.length
    //   ? await accel2FloatingChar[0].readValue()
    //   : new DataView(new ArrayBuffer(12));

    let accel0: Vector3;
    let accel1: Vector3;
    let accel2: Vector3;
    let gyro: Vector3;
    let mag = undefined;
    let magNeg = undefined;
    let avgAccelNed = undefined;
    let avgMagNed = undefined;
    if (this.debugVectors) {
      accel0 = this.parseFloat3(this.debugVectors, 0);
      accel1 = this.parseFloat3(this.debugVectors, 12);
      accel2 = this.parseFloat3(this.debugVectors, 12 * 2);
      gyro = this.parseFloat3(this.debugVectors, 12 * 3);
      mag = this.parseFloat3(this.debugVectors, 12 * 4);
      magNeg = this.parseFloat3(this.debugVectors, 12 * 5);
      avgAccelNed = this.parseFloat3(this.debugVectors, 12 * 6);
      avgMagNed = this.parseFloat3(this.debugVectors, 12 * 7);
    } else {
      const service = await this.bleServer.getPrimaryService(calibrateServiceUuid);
      const accel0Char = await service.getCharacteristic(calibrateAccel0Uuid);
      const accel1Char = await service.getCharacteristic(calibrateAccel1Uuid);
      const accel2Char = await service.getCharacteristic(calibrateAccel2Uuid);
      const gyroChar = await service.getCharacteristic(calibrateGyroUuid);

      const accel0Value: DataView = await accel0Char.readValue();
      const accel1Value: DataView = await accel1Char.readValue();
      const accel2Value: DataView = await accel2Char.readValue();
      const gyroValue: DataView = await gyroChar.readValue();
      accel0 = this.parseFloat3(accel0Value);
      accel1 = this.parseFloat3(accel1Value);
      accel2 = this.parseFloat3(accel2Value);
      gyro = this.parseFloat3(gyroValue);
    }

    let result: DeviceReading = {
      accel0: accel0,
      accel1: accel1,
      accel2: accel2,
      gyro: gyro,
      // accel1Floating: this.parseFloat3(accel1FloatValue),
      // accel2Floating: this.parseFloat3(accel2FloatValue),
    };

    if (this.debugNumbers) {
      result = {
        ...result,
        avgAccelNed: avgAccelNed,
        avgMagNed: avgMagNed,
        mag: mag,
        magNeg: magNeg,
        missedSpi: this.debugNumbers.getInt32(0, true),
        sampleNanos: this.debugNumbers.getInt32(4, true),
        loopNanos: this.debugNumbers.getInt32(8, true),
        gravityCorrectMicroDeg: this.debugNumbers.getInt32(12, true),
        gravityCorrectMicroPercent: this.debugNumbers.getInt32(16, true),
      };
      if (this.debugNumbers.byteLength > 20) {
        result = {
          ...result,
          samplesNoMotion: this.debugNumbers.getInt32(20, true),
        };
      }

      if (this.debugNumbers.byteLength > 24) {
        result = {
          ...result,
          battVolts: this.debugNumbers.getInt16(24, true) * 1.0e-3,
          isCharging: this.debugNumbers.getInt8(26) !== 0,
          usbCc1: this.debugNumbers.getInt8(27) !== 0,
          usbCc2: this.debugNumbers.getInt8(28) !== 0,
          wcoEnabled: this.debugNumbers.getInt8(29) !== 0,
        };
      }

      if (this.debugNumbers.byteLength > 30) {
        // next one
      }
    }

    return result;
  }

  private parseFloat3(data: DataView, startIndex: number = 0): Vector3 {
    return [
      data.getFloat32(startIndex, true),
      data.getFloat32(startIndex + 4, true),
      data.getFloat32(startIndex + 8, true),
    ];
  }

  private prevKey(calKey: AccelCalibrateKey): AccelCalibrateKey {
    return this.nextKey(this.nextKey(this.nextKey(calKey)));
  }

  private nextKey(calKey: AccelCalibrateKey): AccelCalibrateKey {
    switch (calKey) {
      case "x":
        return "y";
      case "y":
        return "negX";
      case "negX":
        return "negY";
      case "negY":
        return "x";
      default:
        return calKey;
    }
  }

  computeCalibrationValues(readings: DeviceReadings): CalibrationValues {
    const result: CalibrationValues = {
      accel0: this.calibrateAccelerometer(readings.accel0, false),
      accel1: this.calibrateAccelerometer(readings.accel1, false),
      accel2: this.calibrateAccelerometer(readings.accel2, false),
      gyro: this.calibrateGyro(readings.gyro),
      mag: this.calibrateMag(readings.mag),
    };

    if (!result.mag) {
      delete result.mag;
    }
    console.log(result);

    return result;
  }

  calibrateMag(mag: AccelerometerCalibrationReadings): Vector6 | undefined {
    if (this.state.singleMeasureCalibrate) {
      return undefined;
    }
    const x = mag["x"][0];
    const negX = mag["negX"][0];
    const y = mag["y"][1];
    const negY = mag["negY"][1];
    const z = mag["z"][2];
    const negZ = mag["negZ"][2];

    if (x == 0.0 && y == 0.0 && z == 0.0) {
      // if everything is zero that means we don't have mag data
      return undefined;
    }

    const diffX = Math.abs((x - negX) / 2);
    const diffY = Math.abs((y - negY) / 2);
    const diffZ = Math.abs((z - negZ) / 2);

    // this is configured for overland park, ks
    const totalMagGauss = 0.516961;
    const totalYearlyDrift = -0.001096;

    const vericalMagGauss = 0.473802;
    const vericalYearlyDrift = -0.001241;

    const vericalMagGaussRWC = 0.415736;

    const scaleX = vericalMagGauss / diffX;
    const scaleY = vericalMagGauss / diffY;
    const scaleZ = vericalMagGauss / diffZ;

    const avgX = (x + negX) / 2;
    const avgY = (y + negY) / 2;
    const avgZ = (z + negZ) / 2;

    const bx = -avgX * scaleX;
    const by = -avgY * scaleY;
    const bz = -avgZ * scaleZ;

    return [scaleX, scaleY, scaleZ, bx, by, bz];
  }

  calibrateGyro(readings: AccelerometerCalibrationReadings): Vector6 {
    if (this.state.singleMeasureCalibrate) {
      const reading = readings.z;
      const reading2 = readings.z180;
      if (reading === undefined || reading2 === undefined) {
        throw new Error("cannot calibrate");
      }
      const x0 = reading[0];
      const x1 = reading2[0];
      const y0 = reading[1];
      const y1 = reading2[1];
      const z0 = reading[2];
      const z1 = reading2[2];

      const avgX = (x1 + x0) / 2;
      const avgY = (y1 + y0) / 2;
      const avgZ = (z1 + z0) / 2;
      return [1.0, 1.0, 1.0, -avgX, -avgY, -avgZ];
    }
    const xValues: number[] = [];
    const yValues = [];
    const zValues = [];
    for (const key of calibrationKeyNames) {
      // @ts-ignore
      xValues.push(readings[key][0]);
      // @ts-ignore
      yValues.push(readings[key][1]);
      // @ts-ignore
      zValues.push(readings[key][2]);
    }

    return [1.0, 1.0, 1.0, -this.median(xValues), -this.median(yValues), -this.median(zValues)];
  }

  median(values: number[]): number {
    values.sort(function (a, b) {
      return a - b;
    });

    const half = Math.floor(values.length / 2);
    return (values[half - 1] + values[half]) / 2.0;
  }

  linearRegression(y: number[], x: number[]) {
    const n = y.length;
    let sum_x = 0;
    let sum_y = 0;
    let sum_xy = 0;
    let sum_xx = 0;
    let sum_yy = 0;

    for (let i = 0; i < y.length; i++) {
      sum_x += x[i];
      sum_y += y[i];
      sum_xy += x[i] * y[i];
      sum_xx += x[i] * x[i];
      sum_yy += y[i] * y[i];
    }

    const slope = (n * sum_xy - sum_x * sum_y) / (n * sum_xx - sum_x * sum_x);
    const intercept = (sum_y - slope * sum_x) / n;
    return { slope, intercept };
  }

  calibrateAccelerometer(
    readings: AccelerometerCalibrationReadings,
    useLinReg: boolean = true,
  ): Vector6 {
    if (this.state.singleMeasureCalibrate) {
      const reading = readings.z;
      const reading2 = readings.z180;
      if (reading === undefined || reading2 === undefined) {
        throw new Error("cannot calibrate");
      }
      const x0 = reading[0];
      const x1 = reading2[0];
      const y0 = reading[1];
      const y1 = reading2[1];
      const z0 = reading[2];
      const z1 = reading2[2];

      const dx = (x1 - x0) / 2;
      const dy = (y1 - y0) / 2;

      const avgX = (x1 + x0) / 2;
      const avgY = (y1 + y0) / 2;
      const avgZ = (z1 + z0) / 2;

      const adjustedZ = Math.sqrt(avgZ * avgZ + dx * dx + dy * dy);
      return [1.0, 1.0, 1.0, -avgX, -avgY, (sensorsGravityStandard - adjustedZ) * Math.sign(avgZ)];
    }
    // @ts-ignore
    const scaleX = (readings.x[0] - readings.negX[0]) / sensorsGravityStandard / 2;
    // @ts-ignore
    const scaleY = (readings.y[1] - readings.negY[1]) / sensorsGravityStandard / 2;
    // @ts-ignore
    const scaleZ = (readings.z[2] - readings.negZ[2]) / sensorsGravityStandard / 2;

    // @ts-ignore
    const sumX = (readings.x[0] + readings.negX[0]) / 2;
    // @ts-ignore
    const sumY = (readings.y[1] + readings.negY[1]) / 2;
    // @ts-ignore
    const sumZ = (readings.z[2] + readings.negZ[2]) / 2;

    const mx = 1.0 / scaleX;
    const my = 1.0 / scaleY;
    const mz = 1.0 / scaleZ;

    // for example x measurements may be 0g, 4g, 2g, 2g, 2g, 2g
    // scale will be 2 so mx = 0.5
    // bx = -2g * mx = -1g
    const bx = -sumX * mx;
    const by = -sumY * my;
    const bz = -sumZ * mz;

    if (useLinReg) {
      const yX = [
        sensorsGravityStandard, // x
        0, // y
        -sensorsGravityStandard, // negX
        0, // negY
        0, // z
        0, // negZ
      ];
      const yY = [0, sensorsGravityStandard, 0, -sensorsGravityStandard, 0, 0];
      const yZ = [0, 0, 0, 0, sensorsGravityStandard, -sensorsGravityStandard];
      const xX: number[] = [];
      const xY: number[] = [];
      const xZ: number[] = [];

      for (const key of calibrationKeyNames) {
        // @ts-ignore
        xX.push(readings[key][0]);
        // @ts-ignore
        xY.push(readings[key][1]);
        // @ts-ignore
        xZ.push(readings[key][2]);
      }
      const lrX = this.linearRegression(yX, xX);
      const lrY = this.linearRegression(yY, xY);
      const lrZ = this.linearRegression(yZ, xZ);
      return [lrX.slope, lrY.slope, lrZ.slope, lrX.intercept, lrY.intercept, lrZ.intercept];
    }
    return [mx, my, mz, bx, by, bz];
  }

  // fetch(): void {
  //   this.index = 0;
  //   this.nusSendString("fetch");
  // }

  download(): void {
    this.index = 0;
    this.nusSendString("download");
  }

  downloadFlight(): void {
    if (this.downloadingFlight) {
      clearInterval(this.downloadingFlight);
      this.downloadingFlight = null;
    }
    this.index = 0;
    if (!this.lastUpload) {
      // We can't download a flight if we don't have the throw.
      return;
    }
    this.downloadingFlight = setTimeout(() => {
      this.downloadingFlight = null;
    }, 30 * 1000);
    this.nusSendString("flight");
  }

  delayPromise(delay: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, delay);
    });
  }

  nusSendString(s: string): Promise<any> {
    if (this.bleDevice && this.bleDevice.gatt.connected) {
      console.log("send: " + s);
      const val_arr = new Uint8Array(s.length);
      for (let i = 0; i < s.length; i++) {
        val_arr[i] = s[i].charCodeAt(0);
      }
      return this.rxCharacteristic.writeValue(val_arr).catch((error: any) => {
        console.log(error);
        return Promise.resolve()
          .then(() => this.delayPromise(60))
          .then(() => this.rxCharacteristic.writeValue(val_arr));
      });
    } else {
      console.log("Not connected to a device yet.");
      return Promise.resolve();
    }
  }

  private async storeCalibrationValuesToDb(values: CalibrationValues, readings: DeviceReadings) {
    const deviceUid = this.deviceUid;
    const id = this.props.isDeviceBuild ? deviceUid : this.getDeviceId();
    if (!id) {
      this.props.notify?.("error", "No device id found.");
      return;
    }
    const user = getAuth(firebaseApp).currentUser;
    const userId = getUserId(user);
    const seconds = Math.floor(Date.now() / 1000);

    const deviceCal: DeviceCalibration = {
      calibration: values,
      readings: readings,
      calibrationTime: Date.now(),
      softwareVersion: this.deviceSoftware,
      calibrationUser: getTrueUserId(),
    };

    if (this.deviceSoftware) {
      deviceCal.softwareVersion = this.deviceSoftware;
    }

    if (this.state.lastReading) {
      deviceCal.batteryVoltage = this.state.lastReading.battVolts;
      deviceCal.sensorHz = 1.0e9 / this.state.lastReading.sampleNanos;
    }

    if (this.props.isDeviceBuild) {
      const toStore: Partial<BuildDevice> = {
        lastCalibration: deviceCal,
      };
      await storeBuildDevice(id, toStore);
    } else {
      await storeDevice(userId, id, deviceCal);
    }
    await storeCalibrationData(userId, id, seconds.toString(), deviceCal, this.props.isDeviceBuild);
  }

  private getDeviceId(): string {
    if (this.deviceId) {
      return this.deviceId;
    }

    if (!this.bleDevice) {
      throw new Error("bleDevice not configured");
    }

    // Old code for old discs that don't expose the mac address
    let id: string = this.bleDevice.id;
    id = id.replace(/\//g, "_");
    id = id.replace(/\+/g, "-");
    return id.replace(/=/g, "");
  }
}
