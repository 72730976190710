import { objectAssign } from '../utils/lang/objectAssign';
import { validateAttributes, validateEvent, validateEventValue, validateEventProperties, validateKey, validateSplit, validateSplits, validateTrafficType, validateIfNotDestroyed, validateIfOperational } from '../utils/inputValidation';
import { startsWith } from '../utils/lang';
import { CONTROL, CONTROL_WITH_CONFIG, GET_TREATMENT, GET_TREATMENTS, GET_TREATMENTS_BY_FLAG_SET, GET_TREATMENTS_BY_FLAG_SETS, GET_TREATMENTS_WITH_CONFIG, GET_TREATMENTS_WITH_CONFIG_BY_FLAG_SET, GET_TREATMENTS_WITH_CONFIG_BY_FLAG_SETS, GET_TREATMENT_WITH_CONFIG, TRACK_FN_LABEL } from '../utils/constants';
import { isConsumerMode } from '../utils/settingsValidation/mode';
import { validateFlagSets } from '../utils/settingsValidation/splitFilters';
/**
 * Decorator that validates the input before actually executing the client methods.
 * We should "guard" the client here, while not polluting the "real" implementation of those methods.
 */
export function clientInputValidationDecorator(settings, client, readinessManager) {
    var log = settings.log, mode = settings.mode;
    var isAsync = isConsumerMode(mode);
    /**
     * Avoid repeating this validations code
     */
    function validateEvaluationParams(maybeKey, maybeFeatureFlagNameOrNames, maybeAttributes, methodName, maybeFlagSetNameOrNames) {
        var multi = startsWith(methodName, GET_TREATMENTS);
        var key = validateKey(log, maybeKey, methodName);
        var splitOrSplits = false;
        var flagSetOrFlagSets = [];
        if (maybeFeatureFlagNameOrNames) {
            splitOrSplits = multi ? validateSplits(log, maybeFeatureFlagNameOrNames, methodName) : validateSplit(log, maybeFeatureFlagNameOrNames, methodName);
        }
        var attributes = validateAttributes(log, maybeAttributes, methodName);
        var isNotDestroyed = validateIfNotDestroyed(log, readinessManager, methodName);
        if (maybeFlagSetNameOrNames) {
            flagSetOrFlagSets = validateFlagSets(log, methodName, maybeFlagSetNameOrNames, settings.sync.__splitFiltersValidation.groupedFilters.bySet);
        }
        validateIfOperational(log, readinessManager, methodName, splitOrSplits);
        var valid = isNotDestroyed && key && (splitOrSplits || flagSetOrFlagSets.length > 0) && attributes !== false;
        return {
            valid: valid,
            key: key,
            splitOrSplits: splitOrSplits,
            flagSetOrFlagSets: flagSetOrFlagSets,
            attributes: attributes
        };
    }
    function wrapResult(value) {
        return isAsync ? Promise.resolve(value) : value;
    }
    function getTreatment(maybeKey, maybeFeatureFlagName, maybeAttributes) {
        var params = validateEvaluationParams(maybeKey, maybeFeatureFlagName, maybeAttributes, GET_TREATMENT);
        if (params.valid) {
            return client.getTreatment(params.key, params.splitOrSplits, params.attributes);
        }
        else {
            return wrapResult(CONTROL);
        }
    }
    function getTreatmentWithConfig(maybeKey, maybeFeatureFlagName, maybeAttributes) {
        var params = validateEvaluationParams(maybeKey, maybeFeatureFlagName, maybeAttributes, GET_TREATMENT_WITH_CONFIG);
        if (params.valid) {
            return client.getTreatmentWithConfig(params.key, params.splitOrSplits, params.attributes);
        }
        else {
            return wrapResult(objectAssign({}, CONTROL_WITH_CONFIG));
        }
    }
    function getTreatments(maybeKey, maybeFeatureFlagNames, maybeAttributes) {
        var params = validateEvaluationParams(maybeKey, maybeFeatureFlagNames, maybeAttributes, GET_TREATMENTS);
        if (params.valid) {
            return client.getTreatments(params.key, params.splitOrSplits, params.attributes);
        }
        else {
            var res_1 = {};
            if (params.splitOrSplits)
                params.splitOrSplits.forEach(function (split) { return res_1[split] = CONTROL; });
            return wrapResult(res_1);
        }
    }
    function getTreatmentsWithConfig(maybeKey, maybeFeatureFlagNames, maybeAttributes) {
        var params = validateEvaluationParams(maybeKey, maybeFeatureFlagNames, maybeAttributes, GET_TREATMENTS_WITH_CONFIG);
        if (params.valid) {
            return client.getTreatmentsWithConfig(params.key, params.splitOrSplits, params.attributes);
        }
        else {
            var res_2 = {};
            if (params.splitOrSplits)
                params.splitOrSplits.forEach(function (split) { return res_2[split] = objectAssign({}, CONTROL_WITH_CONFIG); });
            return wrapResult(res_2);
        }
    }
    function getTreatmentsByFlagSets(maybeKey, maybeFlagSets, maybeAttributes) {
        var params = validateEvaluationParams(maybeKey, undefined, maybeAttributes, GET_TREATMENTS_BY_FLAG_SETS, maybeFlagSets);
        if (params.valid) {
            return client.getTreatmentsByFlagSets(params.key, params.flagSetOrFlagSets, params.attributes);
        }
        else {
            return wrapResult({});
        }
    }
    function getTreatmentsWithConfigByFlagSets(maybeKey, maybeFlagSets, maybeAttributes) {
        var params = validateEvaluationParams(maybeKey, undefined, maybeAttributes, GET_TREATMENTS_WITH_CONFIG_BY_FLAG_SETS, maybeFlagSets);
        if (params.valid) {
            return client.getTreatmentsWithConfigByFlagSets(params.key, params.flagSetOrFlagSets, params.attributes);
        }
        else {
            return wrapResult({});
        }
    }
    function getTreatmentsByFlagSet(maybeKey, maybeFlagSet, maybeAttributes) {
        var params = validateEvaluationParams(maybeKey, undefined, maybeAttributes, GET_TREATMENTS_BY_FLAG_SET, [maybeFlagSet]);
        if (params.valid) {
            return client.getTreatmentsByFlagSet(params.key, params.flagSetOrFlagSets[0], params.attributes);
        }
        else {
            return wrapResult({});
        }
    }
    function getTreatmentsWithConfigByFlagSet(maybeKey, maybeFlagSet, maybeAttributes) {
        var params = validateEvaluationParams(maybeKey, undefined, maybeAttributes, GET_TREATMENTS_WITH_CONFIG_BY_FLAG_SET, [maybeFlagSet]);
        if (params.valid) {
            return client.getTreatmentsWithConfigByFlagSet(params.key, params.flagSetOrFlagSets[0], params.attributes);
        }
        else {
            return wrapResult({});
        }
    }
    function track(maybeKey, maybeTT, maybeEvent, maybeEventValue, maybeProperties) {
        var key = validateKey(log, maybeKey, TRACK_FN_LABEL);
        var tt = validateTrafficType(log, maybeTT, TRACK_FN_LABEL);
        var event = validateEvent(log, maybeEvent, TRACK_FN_LABEL);
        var eventValue = validateEventValue(log, maybeEventValue, TRACK_FN_LABEL);
        var _a = validateEventProperties(log, maybeProperties, TRACK_FN_LABEL), properties = _a.properties, size = _a.size;
        var isNotDestroyed = validateIfNotDestroyed(log, readinessManager, TRACK_FN_LABEL);
        if (isNotDestroyed && key && tt && event && eventValue !== false && properties !== false) { // @ts-expect-error
            return client.track(key, tt, event, eventValue, properties, size);
        }
        else {
            return isAsync ? Promise.resolve(false) : false;
        }
    }
    return {
        getTreatment: getTreatment,
        getTreatmentWithConfig: getTreatmentWithConfig,
        getTreatments: getTreatments,
        getTreatmentsWithConfig: getTreatmentsWithConfig,
        getTreatmentsByFlagSets: getTreatmentsByFlagSets,
        getTreatmentsWithConfigByFlagSets: getTreatmentsWithConfigByFlagSets,
        getTreatmentsByFlagSet: getTreatmentsByFlagSet,
        getTreatmentsWithConfigByFlagSet: getTreatmentsWithConfigByFlagSet,
        track: track
    };
}
