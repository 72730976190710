import {
  AdminPanelSettingsOutlined,
  LeaderboardOutlined,
  PersonSearchOutlined,
  ShoppingCartOutlined,
  Speed,
  SvgIconComponent,
} from "@mui/icons-material";
import {
  Divider,
  Icon,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import DatasetIcon from "@mui/icons-material/DatasetOutlined";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import MovieOutlinedIcon from "@mui/icons-material/MovieOutlined";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import { firebaseApp } from "../../firebaseConfig";
import { useGlobal } from "../providers/GlobalProvider";
import RackIcon from "../icons/RackIcon";
import { useTranslation } from "react-i18next";
import RuleIcon from "@mui/icons-material/Rule";
export type NavigationMenuProps = {
  menuRef: HTMLElement;
  onClose: () => void;
};

type NavigationItem = {
  label: string;
  icon: SvgIconComponent;
  href: string;
  authorized: boolean;
};

export default function NavigationMenu(props: NavigationMenuProps) {
  const { menuRef, onClose: handleClose } = props;
  const [user] = useAuthState(getAuth(firebaseApp));
  const { isManager, isPremiumStore, isStore, isAdmin, featureFlags } = useGlobal();
  const { t } = useTranslation();
  const theme = useTheme();
  const publicNavigationItems: NavigationItem[] = [
    {
      label: t("throw_capital_other"),
      icon: DatasetIcon,
      href: "/throws",
      authorized: true,
    },
    {
      label: t("throw_set_capital_other"),
      icon: TrendingUpOutlinedIcon,
      href: "/throws/sets",
      authorized: true,
    },
    {
      label: t("disc_capital_other"),
      icon: () => <RackIcon color={theme.palette.grey[600]} sx={{ fontSize: "16px" }} />,
      href: "/discs",
      authorized: !!featureFlags.feature_collection,
    },
    {
      label: t("stockShots"),
      icon: RuleIcon,
      href: "/stock-shots",
      authorized: !!featureFlags.lab,
    },
    {
      label: t("simulator_capital_one"),
      icon: MovieOutlinedIcon,
      href: "/simulator",
      authorized: true,
    },
    {
      label: t("test_drive"),
      icon: Speed,
      href: "/test-drive",
      authorized: isStore,
    },
    {
      label: t("leaderboards"),
      icon: LeaderboardOutlined,
      href: "/leaderboards",
      authorized: isPremiumStore,
    },
  ];

  const internalNavigationItems = [
    {
      label: t("user_management"),
      icon: AdminPanelSettingsOutlined,
      href: "/admin/permissions",
      authorized: [isAdmin, isManager].some(Boolean),
    },
    {
      label: t("inspect_user"),
      icon: PersonSearchOutlined,
      href: "/admin/inspect",
      authorized: [isAdmin, isManager].some(Boolean),
    },
  ];

  const sx = {
    alignItems: "center",
    color: theme.palette.grey[800],
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.grey[800],
      textDecoration: "none",
    },
    "&:visited": {
      alignItems: "center",
      color: theme.palette.grey[800],
      textDecoration: "none",
    },
    "&:focus": {
      alignItems: "center",
      color: theme.palette.grey[800],
      textDecoration: "none",
    },
  };

  return (
    <Menu
      open={Boolean(menuRef)}
      onClose={handleClose}
      anchorEl={menuRef}
      sx={{ ".MuiList-root": { minWidth: "175px" } }}
    >
      {!user?.uid && (
        <MenuItem
          component={Link}
          onClick={handleClose}
          target="_blank"
          href="https://shop.techdisc.com/"
          id="shop-link"
          variant="body1"
          sx={{ ...sx, display: { mobile: "flex", lg: "none" } }}
        >
          <ListItemIcon>
            <Icon
              sx={{ color: theme.palette.grey[600], mr: 0.5 }}
              fontSize="small"
              component={ShoppingCartOutlined}
            />
          </ListItemIcon>
          <ListItemText>{t("shop")}</ListItemText>
        </MenuItem>
      )}
      {publicNavigationItems.map((item) =>
        item.authorized ? (
          <MenuItem key={item.href} sx={sx} component={Link} onClick={handleClose} href={item.href}>
            <ListItemIcon>
              <Icon
                sx={{ color: theme.palette.grey[600], mr: 0.5 }}
                fontSize="small"
                component={item.icon}
              />
            </ListItemIcon>
            <ListItemText>{item.label}</ListItemText>
          </MenuItem>
        ) : null,
      )}
      {(isAdmin || isManager) && <Divider key="divider" />}
      {internalNavigationItems.map((item) =>
        item.authorized ? (
          <MenuItem key={item.href} sx={sx} component={Link} onClick={handleClose} href={item.href}>
            <ListItemIcon>
              <Icon
                sx={{ color: theme.palette.grey[600], mr: 0.5 }}
                fontSize="small"
                component={item.icon}
              />
            </ListItemIcon>
            <ListItemText>{item.label}</ListItemText>
          </MenuItem>
        ) : null,
      )}
    </Menu>
  );
}
