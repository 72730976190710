import { LOG_PREFIX_UNIQUE_KEYS_TRACKER } from '../logger/constants';
var noopFilterAdapter = {
    add: function () { return true; },
    contains: function () { return true; },
    clear: function () { }
};
/**
 * Trackes uniques keys
 * Unique Keys Tracker will be in charge of checking if the MTK was already sent to the BE in the last period
 * or schedule to be sent; if not it will be added in an internal cache and sent in the next post.
 *
 * @param log Logger instance
 * @param uniqueKeysCache cache to save unique keys
 * @param filterAdapter filter adapter
 */
export function uniqueKeysTrackerFactory(log, uniqueKeysCache, filterAdapter) {
    if (filterAdapter === void 0) { filterAdapter = noopFilterAdapter; }
    var intervalId;
    if (filterAdapter.refreshRate) {
        intervalId = setInterval(filterAdapter.clear, filterAdapter.refreshRate);
    }
    return {
        track: function (key, featureName) {
            if (!filterAdapter.add(key, featureName)) {
                log.debug(LOG_PREFIX_UNIQUE_KEYS_TRACKER + "The feature " + featureName + " and key " + key + " exist in the filter");
                return;
            }
            uniqueKeysCache.track(key, featureName);
        },
        stop: function () {
            clearInterval(intervalId);
        }
    };
}
