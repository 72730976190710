import { GaToSplit } from './GaToSplit';
export function GoogleAnalyticsToSplit(options) {
    if (options === void 0) { options = {}; }
    // GaToSplit integration factory
    function GoogleAnalyticsToSplitFactory(params) {
        return GaToSplit(options, params);
    }
    GoogleAnalyticsToSplitFactory.type = 'GOOGLE_ANALYTICS_TO_SPLIT';
    return GoogleAnalyticsToSplitFactory;
}
