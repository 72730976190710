import { sdkReadinessManagerFactory } from '../readiness/sdkReadinessManager';
import { impressionsTrackerFactory } from '../trackers/impressionsTracker';
import { eventTrackerFactory } from '../trackers/eventTracker';
import { telemetryTrackerFactory } from '../trackers/telemetryTracker';
import { validateAndTrackApiKey } from '../utils/inputValidation/apiKey';
import { createLoggerAPI } from '../logger/sdkLogger';
import { NEW_FACTORY, RETRIEVE_MANAGER } from '../logger/constants';
import { SDK_SPLITS_ARRIVED, SDK_SEGMENTS_ARRIVED } from '../readiness/constants';
import { objectAssign } from '../utils/lang/objectAssign';
import { strategyDebugFactory } from '../trackers/strategy/strategyDebug';
import { strategyOptimizedFactory } from '../trackers/strategy/strategyOptimized';
import { strategyNoneFactory } from '../trackers/strategy/strategyNone';
import { uniqueKeysTrackerFactory } from '../trackers/uniqueKeysTracker';
import { NONE, OPTIMIZED } from '../utils/constants';
/**
 * Modular SDK factory
 */
export function sdkFactory(params) {
    var settings = params.settings, platform = params.platform, storageFactory = params.storageFactory, splitApiFactory = params.splitApiFactory, extraProps = params.extraProps, syncManagerFactory = params.syncManagerFactory, SignalListener = params.SignalListener, impressionsObserverFactory = params.impressionsObserverFactory, integrationsManagerFactory = params.integrationsManagerFactory, sdkManagerFactory = params.sdkManagerFactory, sdkClientMethodFactory = params.sdkClientMethodFactory, filterAdapterFactory = params.filterAdapterFactory;
    var log = settings.log, impressionsMode = settings.sync.impressionsMode;
    // @TODO handle non-recoverable errors, such as, global `fetch` not available, invalid SDK Key, etc.
    // On non-recoverable errors, we should mark the SDK as destroyed and not start synchronization.
    // We will just log and allow for the SDK to end up throwing an SDK_TIMEOUT event for devs to handle.
    validateAndTrackApiKey(log, settings.core.authorizationKey);
    var sdkReadinessManager = sdkReadinessManagerFactory(log, platform.EventEmitter, settings.startup.readyTimeout);
    var readiness = sdkReadinessManager.readinessManager;
    var storage = storageFactory({
        settings: settings,
        onReadyCb: function (error) {
            if (error) {
                // If storage fails to connect, SDK_READY_TIMED_OUT event is emitted immediately. Review when timeout and non-recoverable errors are reworked
                readiness.timeout();
                return;
            }
            readiness.splits.emit(SDK_SPLITS_ARRIVED);
            readiness.segments.emit(SDK_SEGMENTS_ARRIVED);
        },
    });
    // @TODO add support for dataloader: `if (params.dataLoader) params.dataLoader(storage);`
    var telemetryTracker = telemetryTrackerFactory(storage.telemetry, platform.now);
    var integrationsManager = integrationsManagerFactory && integrationsManagerFactory({ settings: settings, storage: storage, telemetryTracker: telemetryTracker });
    var observer = impressionsObserverFactory();
    var uniqueKeysTracker = impressionsMode === NONE ? uniqueKeysTrackerFactory(log, storage.uniqueKeys, filterAdapterFactory && filterAdapterFactory()) : undefined;
    var strategy;
    switch (impressionsMode) {
        case OPTIMIZED:
            strategy = strategyOptimizedFactory(observer, storage.impressionCounts);
            break;
        case NONE:
            strategy = strategyNoneFactory(storage.impressionCounts, uniqueKeysTracker);
            break;
        default:
            strategy = strategyDebugFactory(observer);
    }
    var impressionsTracker = impressionsTrackerFactory(settings, storage.impressions, strategy, integrationsManager, storage.telemetry);
    var eventTracker = eventTrackerFactory(settings, storage.events, integrationsManager, storage.telemetry);
    // splitApi is used by SyncManager and Browser signal listener
    var splitApi = splitApiFactory && splitApiFactory(settings, platform, telemetryTracker);
    var ctx = { splitApi: splitApi, eventTracker: eventTracker, impressionsTracker: impressionsTracker, telemetryTracker: telemetryTracker, uniqueKeysTracker: uniqueKeysTracker, sdkReadinessManager: sdkReadinessManager, readiness: readiness, settings: settings, storage: storage, platform: platform };
    var syncManager = syncManagerFactory && syncManagerFactory(ctx);
    ctx.syncManager = syncManager;
    var signalListener = SignalListener && new SignalListener(syncManager, settings, storage, splitApi);
    ctx.signalListener = signalListener;
    // SDK client and manager
    var clientMethod = sdkClientMethodFactory(ctx);
    var managerInstance = sdkManagerFactory(settings, storage.splits, sdkReadinessManager);
    syncManager && syncManager.start();
    signalListener && signalListener.start();
    log.info(NEW_FACTORY);
    // @ts-ignore
    return objectAssign({
        // Split evaluation and event tracking engine
        client: clientMethod,
        // Manager API to explore available information
        manager: function () {
            log.debug(RETRIEVE_MANAGER);
            return managerInstance;
        },
        // Logger wrapper API
        Logger: createLoggerAPI(log),
        settings: settings,
    }, extraProps && extraProps(ctx));
}
