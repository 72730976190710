import { matchersTransform } from '../matchersTransform';
import { Treatments } from '../treatments';
import { matcherFactory } from '../matchers';
import { sanitizeValue } from '../value';
import { conditionContext } from '../condition';
import { ifElseIfCombinerContext } from '../combiners/ifelseif';
import { andCombinerContext } from '../combiners/and';
import { thenable } from '../../utils/promise/thenable';
import { ENGINE_MATCHER_ERROR, ENGINE_MATCHER_RESULT } from '../../logger/constants';
export function parser(log, conditions, storage) {
    var predicates = [];
    for (var i = 0; i < conditions.length; i++) {
        var _a = conditions[i], matcherGroup = _a.matcherGroup, partitions = _a.partitions, label = _a.label, conditionType = _a.conditionType;
        // transform data structure
        var matchers = matchersTransform(matcherGroup.matchers);
        // create a set of pure functions from the matcher's dto
        var expressions = matchers.map(function (matcherDto) {
            var matcher;
            try {
                matcher = matcherFactory(log, matcherDto, storage);
            }
            catch (error) {
                log.error(ENGINE_MATCHER_ERROR, [matcherDto.name, error]);
            }
            // Evaluator function.
            return function (key, attributes, splitEvaluator) {
                var value = sanitizeValue(log, key, matcherDto, attributes);
                var result = false;
                if (value !== undefined && matcher) {
                    try {
                        result = matcher(value, splitEvaluator);
                    }
                    catch (error) {
                        log.error(ENGINE_MATCHER_ERROR, [matcherDto.name, error]);
                    }
                }
                function handleResult(result) {
                    log.debug(ENGINE_MATCHER_RESULT, [matcherDto.name, result, matcherDto.value, value]); // @ts-ignore
                    return Boolean(result ^ matcherDto.negate);
                }
                return thenable(result) ?
                    result.then(handleResult) :
                    handleResult(result);
            };
        });
        // if matcher's factory can't instantiate the matchers, the expressions array will be empty
        if (expressions.length === 0) {
            // reset any data collected during parsing
            predicates = [];
            // and break the loop
            break;
        }
        predicates.push(conditionContext(log, andCombinerContext(log, expressions), Treatments.parse(partitions), label, conditionType));
    }
    // Instanciate evaluator given the set of conditions using if else if logic
    return ifElseIfCombinerContext(log, predicates);
}
