import { FLAGS_SPEC } from '../utils/constants';
import { startsWith } from '../utils/lang';
import { hash } from '../utils/murmur3/murmur3';
var everythingAtTheEnd = /[^.]+$/;
var DEFAULT_PREFIX = 'SPLITIO';
export function validatePrefix(prefix) {
    return prefix ? prefix + '.SPLITIO' : 'SPLITIO';
}
var KeyBuilder = /** @class */ (function () {
    function KeyBuilder(prefix) {
        if (prefix === void 0) { prefix = DEFAULT_PREFIX; }
        this.prefix = prefix;
    }
    KeyBuilder.prototype.buildTrafficTypeKey = function (trafficType) {
        return this.prefix + ".trafficType." + trafficType;
    };
    KeyBuilder.prototype.buildFlagSetKey = function (flagSet) {
        return this.prefix + ".flagSet." + flagSet;
    };
    KeyBuilder.prototype.buildSplitKey = function (splitName) {
        return this.prefix + ".split." + splitName;
    };
    KeyBuilder.prototype.buildSplitsTillKey = function () {
        return this.prefix + ".splits.till";
    };
    // NOT USED
    // buildSplitsReady() {
    //   return `${this.prefix}.splits.ready`;
    // }
    KeyBuilder.prototype.isSplitKey = function (key) {
        return startsWith(key, this.prefix + ".split.");
    };
    KeyBuilder.prototype.buildSplitKeyPrefix = function () {
        return this.prefix + ".split.";
    };
    // Only used by InLocalStorage.
    KeyBuilder.prototype.buildSplitsWithSegmentCountKey = function () {
        return this.prefix + ".splits.usingSegments";
    };
    KeyBuilder.prototype.buildSegmentNameKey = function (segmentName) {
        return this.prefix + ".segment." + segmentName;
    };
    KeyBuilder.prototype.buildSegmentTillKey = function (segmentName) {
        return this.prefix + ".segment." + segmentName + ".till";
    };
    // NOT USED
    // buildSegmentsReady() {
    //   return `${this.prefix}.segments.ready`;
    // }
    KeyBuilder.prototype.extractKey = function (builtKey) {
        var s = builtKey.match(everythingAtTheEnd);
        if (s && s.length) {
            return s[0];
        }
        else {
            throw new Error('Invalid latency key provided');
        }
    };
    KeyBuilder.prototype.buildHashKey = function () {
        return this.prefix + ".hash";
    };
    return KeyBuilder;
}());
export { KeyBuilder };
/**
 * Generates a murmur32 hash based on the authorization key, the feature flags filter query, and version of SplitChanges API.
 * The hash is in hexadecimal format (8 characters max, 32 bits).
 */
export function getStorageHash(settings) {
    return hash(settings.core.authorizationKey + "::" + settings.sync.__splitFiltersValidation.queryString + "::" + FLAGS_SPEC).toString(16);
}
