import { __assign } from "tslib";
import React from 'react';
import { SplitComponent } from './SplitClient';
import { WARN_SF_CONFIG_AND_FACTORY } from './constants';
import { getSplitFactory, destroySplitFactory, getSplitClient, getStatus } from './utils';
import { DEFAULT_UPDATE_OPTIONS } from './useSplitClient';
/**
 * SplitFactoryProvider will initialize the Split SDK and its main client when `config` prop is provided or updated, listen for its events in order to update the Split Context,
 * and automatically destroy the SDK (shutdown and release resources) when it is unmounted or `config` prop updated. SplitFactoryProvider must wrap other library components and
 * functions since they access the Split Context and its properties (factory, client, isReady, etc).
 *
 * NOTE: Either pass a `factory` instance or a `config` object as props. If both props are passed, the `config` prop will be ignored.
 * Pass the same reference to the `config` or `factory` object rather than a new instance on each render, to avoid unnecessary props changes and SDK reinitializations.
 *
 * @see {@link https://help.split.io/hc/en-us/articles/360038825091-React-SDK#2-instantiate-the-sdk-and-create-a-new-split-client}
 */
export function SplitFactoryProvider(props) {
    var _a = __assign(__assign({}, DEFAULT_UPDATE_OPTIONS), props), config = _a.config, propFactory = _a.factory, updateOnSdkReady = _a.updateOnSdkReady, updateOnSdkReadyFromCache = _a.updateOnSdkReadyFromCache, updateOnSdkTimedout = _a.updateOnSdkTimedout, updateOnSdkUpdate = _a.updateOnSdkUpdate;
    if (config && propFactory) {
        console.log(WARN_SF_CONFIG_AND_FACTORY);
        config = undefined;
    }
    var _b = React.useState(null), configFactory = _b[0], setConfigFactory = _b[1];
    var factory = propFactory || (configFactory && config === configFactory.config ? configFactory : null);
    var client = factory ? getSplitClient(factory) : null;
    // Effect to initialize and destroy the factory
    React.useEffect(function () {
        if (config) {
            var factory_1 = getSplitFactory(config);
            return function () {
                destroySplitFactory(factory_1);
            };
        }
    }, [config]);
    // Effect to subscribe/unsubscribe to events
    React.useEffect(function () {
        var factory = config && getSplitFactory(config);
        if (factory) {
            var client_1 = getSplitClient(factory);
            var status_1 = getStatus(client_1);
            // Unsubscribe from events and update state when first event is emitted
            var update_1 = function () {
                unsubscribe_1();
                setConfigFactory(factory);
            };
            var unsubscribe_1 = function () {
                client_1.off(client_1.Event.SDK_READY, update_1);
                client_1.off(client_1.Event.SDK_READY_FROM_CACHE, update_1);
                client_1.off(client_1.Event.SDK_READY_TIMED_OUT, update_1);
                client_1.off(client_1.Event.SDK_UPDATE, update_1);
            };
            if (updateOnSdkReady) {
                if (status_1.isReady)
                    update_1();
                else
                    client_1.once(client_1.Event.SDK_READY, update_1);
            }
            if (updateOnSdkReadyFromCache) {
                if (status_1.isReadyFromCache)
                    update_1();
                else
                    client_1.once(client_1.Event.SDK_READY_FROM_CACHE, update_1);
            }
            if (updateOnSdkTimedout) {
                if (status_1.hasTimedout)
                    update_1();
                else
                    client_1.once(client_1.Event.SDK_READY_TIMED_OUT, update_1);
            }
            if (updateOnSdkUpdate)
                client_1.on(client_1.Event.SDK_UPDATE, update_1);
            return unsubscribe_1;
        }
    }, [config, updateOnSdkReady, updateOnSdkReadyFromCache, updateOnSdkTimedout, updateOnSdkUpdate]);
    return (React.createElement(SplitComponent, __assign({}, props, { factory: factory, client: client })));
}
